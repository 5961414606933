/**Angular and Materials API imports */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
/**
 * Component Imports
 */
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppChatbotComponent } from './chatbot/chatbot.component';
import { ChatbotService } from './services/chat.service';
import { ChatsidenavComponent } from './chatsidenav/chatsidenav.component';
import { RatingModalComponent } from './rating-modal/rating-modal.component';
import { TagPopupModalComponent } from "./chatbot/tagPopupModal";
import { ChathistoryComponent } from './chathistory/chathistory.component';
import { ConversationHistoryComponent } from './conversation-history/conversation-history.component';
import { ConversationDrawerComponent } from './conversation-drawer/conversation-drawer.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordThankyouComponent } from './forgot-password-thankyou/forgot-password-thankyou.component';
import { PasswordResetPageComponent } from './password-reset-page/password-reset-page.component';
import { ConfirmationDialogComponentComponent } from './confirmation-dialog-component/confirmation-dialog-component.component';
import { SynonymsComponent } from './synonyms/synonyms.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { TranslationService, HttpLoaderFactory } from '../app/services/translation-service';
import { DevelopersConfigurationComponent } from './developers-configuration/developers-configuration.component';
import { LoaderComponent } from './loader/loader.component';
import { RatingTypeOptionsListComponent } from './feedback/feedback.component';
import { SensitiveApproveRejectComponent } from './sensitive-approve-reject-pop-up/sensitive-approve-reject.component';
import { RightDrawerSimilarQuestionsComponent } from './right-drawer-similar-questions/right-drawer-similar-questions.component';
import { CacheComponent } from './cache/cache.component';
import { DisclousrePopupModalComponent } from './popup-modal/popup-modal.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ReplacePipe } from './pipe/replace-pipe';
import { ProperCasePipe } from './pipe/propercase-pipe';
import { ValidationComponent } from './validation/validation.component';
import { MatTableComponent } from './shared/mat-table/mat-table.component';
import { DateFormatService } from './pipe/date-time-localize';
import { OverlayComponent } from './pipe/overlay/overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { UserCommentsComponent } from './user-comments/user-comments.component';
import { ReportsComponent } from './reports/reports.component';
import { FrenchCharacterValidatorService } from './services/french-character-validator.service';
import { TokenInterceptor } from './interceptor';
@NgModule({
  declarations: [
    AppComponent,
    AppChatbotComponent,
    ChatsidenavComponent,
    LoginComponent,
    RatingModalComponent,
    TagPopupModalComponent,
    ChathistoryComponent,
    ConversationHistoryComponent,
    ConversationDrawerComponent,
    ContentManagementComponent,
    ForgotPasswordComponent,
    ForgotPasswordThankyouComponent,
    PasswordResetPageComponent,
    ConfirmationDialogComponentComponent,
    SynonymsComponent,
    UsermanagementComponent,
    DevelopersConfigurationComponent,
    LoaderComponent,
    RatingTypeOptionsListComponent,
    SensitiveApproveRejectComponent,
    RightDrawerSimilarQuestionsComponent,
    CacheComponent,
    DisclousrePopupModalComponent,
    ConfigurationComponent,
    ReplacePipe,
    ProperCasePipe,
    ValidationComponent,
    MatTableComponent,
    OverlayComponent,
    UserCommentsComponent,
    ReportsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    FlexLayoutModule,
    MatExpansionModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatAutocompleteModule,
    CommonModule,
    MatListModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressBarModule,
    ToastrModule.forRoot({
      closeButton: true,
      tapToDismiss: false,
      preventDuplicates: true,
      // positionClass: "toast-top-full-width"
    }),
    MatMenuModule,
    MatTableModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatSnackBarModule,
    MatCardModule,
    OverlayModule,
    PortalModule,
    MatChipsModule,
    DragDropModule
  ],
  providers: [
    ChatbotService, 
    DatePipe, 
    TranslationService, 
    DateFormatService, 
    FrenchCharacterValidatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true  // This is important to allow multiple interceptors if needed
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
