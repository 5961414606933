import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { LocalStorageService } from './localStorageService';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ChatbotService } from './chat.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  public userMgmtURL = environment.apiBaseUrl['authService'];
  public token = '';
  private headers: HttpHeaders = new HttpHeaders();
  constructor(private http: HttpClient, private localStroage: LocalStorageService, private toaster: ToastrService, private router: Router, private chatbotService: ChatbotService, private translated: TranslateService) {
    ////this.updateToken();
  }

  // private updateToken(): void {
  //   this.token = this.localStroage.get('appTo');
  //   this.headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.token}`
  //   });
  // }

  getAllUsers(page_number: number, page_size: number): Observable<any> {
    ////this.updateToken();
    return this.http.get(`${this.userMgmtURL}/getusers?page_number=${page_number}&page_size=${page_size}&bot_type=${this.localStroage.get('bot_type')}`, { headers: this.headers }).pipe(
      catchError((error: any) => {
       
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } 
        return throwError(error);
      })
    );
  }

  performUserAction(action: 'adduser' | 'updateuser' | 'deleteuser' | 'searchuser', payload: any) {
    //this.updateToken()
    let url = '';
    switch (action) {
      case 'adduser':
        url = `${this.userMgmtURL}/adminadduser`;
        break;
      case 'updateuser':
        url = `${this.userMgmtURL}/update_user`;
        break;
      case 'deleteuser':
        url = `${this.userMgmtURL}/deleteuser`;
        break;
      case 'searchuser':
        url = `${this.userMgmtURL}/searchuser`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {
       
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } 
        return throwError(error);
      })
    );
  }
}
