<div class="page-containter" [style.paddingLeft]="isDesktop? 20: 0">
    <div *ngIf="!isValidData" fxLayoutAlign="end center" fxLayoutGap="30" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch">
        <button mat-button class="mui-button" (click)="addNewEvaluation()">{{'disclosure.ADD_EVALUATION'| translate}}</button>
        <mat-form-field appearance="fill" fxFlex="35">
            <mat-label>{{'validator.FILTER_SEARCH_TEXT' | translate}}</mat-label>
            <input matInput #searchInput [(ngModel)]="searchQuery" (ngModelChange)="inputChangeSearch($event)" (keydown.enter)="searchTableData(pageNumber)" required>
            <button mat-icon-button matSuffix (click)="searchTableData(pageNumber)">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <span fxLayoutAlign="end center"  class='font-size12' *ngIf="!isValidData">{{'validator.FILTER_SEARCH_HELP_TEXT' | translate}}</span>
    <div *ngIf="isValidData" fxLayout="row" fxLayoutGap="20" fxLayoutAlign="end center" fxLayout.xs="column"
        fxLayoutAlign.xs="end end" fxLayoutGap.xs="10">
        <button mat-button class="mui-button" (click)="backToSearch()">{{'validator.FILTER_BACK' | translate}}</button>
    </div>
    <br>
    <div *ngIf="!isRePhrase">
        <div class="table-content-approved" *ngIf="dataSourceValidationTest.length > 0">
            <div class="table-content-fit-page">
                <div *ngIf="!isLoader; else loading">
                    <app-mat-table [columns]="validationColumns" [data]="dataSourceValidationTest"
                        [pageNumber]="pageNumber" [totalTablePage]="totalTablePage"
                        (onPageChangeEvent)="onPageChange($event)" (usernameClick)="rowClickOption($event)"
                        (compareClick)="clickCompare($event)" (filterQuery)="searchfilter($event)" (updatefavorite)="favoriteStatusUpdate($event)">
                    </app-mat-table>
                    <div *ngIf="dataSourceValidationTest.length === 0" fxLayoutAlign="center center">
                        {{'contentMgmt.NO_RECORD_FOUND' | translate}}
                    </div>
                </div>
                <ng-template #loading>
                    <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                        <app-loader [botLoderType]="botLoderType"></app-loader>
                    </div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="dataSourceValidationTest.length === 0" fxLayoutAlign="center center">
            {{'contentMgmt.NO_RECORD_FOUND' | translate}}
        </div>
    </div>
    <!-- <ng-template #rephraseContent>
        <div>
            <h2>{{'validator.RE_PHRASE_HEAD' | translate}}</h2>
        </div>
        <mat-form-field appearance="fill" class="width-full">
            <mat-label>{{'validator.RE_PHRASE_QUES' | translate}}</mat-label>
            <input matInput [(ngModel)]="rePhraseQuestion">
            <button mat-icon-button matSuffix (click)="clearRePhrQuestion()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="fill" class="width-full">
            <mat-label>{{'validator.RE_PHRASE_ANSW' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="rePhraseGroundTruth" rows="5"></textarea>
            <button mat-icon-button matSuffix (click)="clearRePhrAnswer()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="fill" class="width-full">
            <mat-label>{{'validator.RE_PHRASE_TEXT' | translate}}</mat-label>
            <mat-select [(value)]="isRephraseOptions" (selectionChange)="isRephraseChange()">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="width-full">
            <mat-label>{{'sideNav.TEST_SIZE' | translate}}</mat-label>
            <mat-select [(ngModel)]="testSize">
                <mat-option *ngFor="let option of testSizeOptions" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div fxLayoutAlign="end end" fxLayoutGap="20">
            <button mat-button class="mui-button" (click)="showRePhrase()">{{'devConfig.BACK' | translate}}</button>
            <button mat-button class="mui-button" [disabled]="!isRephraseValid()" (click)="SaveRephrase()">{{'validator.RE_PHRASE_TEXT' |
                translate}}</button>
        </div>
    </ng-template> -->
    <!-- Modal Loader -->
    <div *ngIf="isModalLoader" class="modal-backdrop">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>