import { Component, OnInit, ViewChild, AfterViewInit, ViewContainerRef } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { LocalStorageService } from '../services/localStorageService';
import { ContentManagementService } from '../services/content-management.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { DateFormatService } from '../pipe/date-time-localize';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatbotService } from '../services/chat.service';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { SensitiveApproveRejectComponent } from '../sensitive-approve-reject-pop-up/sensitive-approve-reject.component';
import { TranslateService } from '@ngx-translate/core';
import { TagPopupModalComponent } from '../chatbot/tagPopupModal';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { ChatTranscriptService } from '../services/chat-transcript.service';
@Component({
  selector: 'app-user-comments',
  templateUrl: './user-comments.component.html',
  styleUrls: ['./user-comments.component.scss']
})
export class UserCommentsComponent implements OnInit, AfterViewInit {
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  @ViewChild('approvedSubTabGroup', { static: false }) approvedSubTabGroup!: MatTabGroup;
  @ViewChild('toApproveSubTabGroup', { static: false }) toApproveSubTabGroup!: MatTabGroup;
  @ViewChild('rejectedSubTabGroup', { static: false }) rejectedSubTabGroup!: MatTabGroup;

  approvalStatus: string = 'unapproved';
  botLoderType = 'boxLoader';
  isDesktop = false;
  isLoader = false;
  feedbackTypes: any = '';
  feedbackTypeOptions = ["Sensitive", "No Content", "Normal", "Cache", "Cache Recommendation", "All"];
  approvedData = new MatTableDataSource<any>([]);
  toApproveData = new MatTableDataSource<any>([]);
  rejectedData = new MatTableDataSource<any>([]);
  // Data Sources for Approved Tab
  approvedAllData!: MatTableDataSource<any>;
  approvedCacheData!: MatTableDataSource<any>;

  displayedColumns: string[] = [];
  currentTabLabel: string = 'Pending';
  currentSubTab: string = '';
  noRecordFound: boolean = false;
  isClickable = true;
  modalPopupResponse: any;
  questionInformation = '';
  pageNumber: number = 1;
  pageSize: number = 10;
  totalTablePage: number = 0;
  languageTypeSelected: string = 'all';
  languageTypeOptions = [
    { label: "English", value: "en" },
    { label: "Français", value: "fr" },
    { label: "All", value: 'all' }
  ];
  approveRejectComments: string = '';
  constructor(private getSession: LocalStorageService, private contentMgmtService: ContentManagementService, private toaster: ToastrService, public dateFormat: DateFormatService, public dialog: MatDialog,
    public chatbotService: ChatbotService, private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService, private translated: TranslateService,
    private router: Router, private http: HttpClient, private chatTranscriptService: ChatTranscriptService
  ) {
    if (this.getSession.get('language') === 'en') {
      this.currentSubTab = 'User Comments';
    } else {
      this.currentSubTab = 'Commentaires des utilisateurs';
    }
  }
  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.chatbotService.pref_lang_type || this.getSession.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }

  getFilteredLanguageTypeOptions() {
    if (this.currentSubTab === 'Cache Recommendation') {
      return this.languageTypeOptions.filter(option => option.value !== 'all');
    }
    return this.languageTypeOptions;
  }

  getImageKeys(row: any): any[] {
    if (row.images && row.images.length > 0) {
      // Use Set to remove duplicates
      const keys = new Set(row.images.flatMap((image: any) => Object.keys(image)));
      return Array.from(keys);
    }
    return [];
  }

  previewImage(event: MouseEvent, images: any[], key: string) {
    event.preventDefault();
    const imageUrl = images.find(image => image[key])[key];
    const regex = /view_image/;
    this.isLoader = true;
    if (regex.test(imageUrl)) {
      this.downloadFile(imageUrl).subscribe(
        (response: any) => {
          const contentType = response.type;
          const blob = new Blob([response], { type: contentType });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          this.isLoader = false;
        },
        error => {
          this.isLoader = false;
          this.translated.get('errorMessages.IMAGE_PREVIEW_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
        }
      );
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob', // Specify responseType as blob
      headers: {
        Authorization: `Bearer ${this.getSession.get('appTo')}`
      }
    });
  }

  onDisclosureClick(rowData: any, event: Event) {
    event.preventDefault();
    // You can implement further logic here, such as opening a dialog or navigating to another route
    this.modalPopupResponse = rowData;
    this.questionInformation = rowData.question
    this.openDialog('0ms', '0ms');
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef: MatDialogRef<TagPopupModalComponent> = this.dialog.open(TagPopupModalComponent, {
      width: '100%',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        userInput: this.questionInformation,
        responseFromBot: this.modalPopupResponse,
        fromRightDrawer: false
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
  updateDisplayedColumns() {
    if (this.feedbackTypes === 'Cache Recommendation') {
      this.displayedColumns = [
        'question',
        'response',
        'username',
        // 'approved_rejected_by',
        'given_timestamp',
        'language_type',
        'images',
        'model_update_status'
      ];
      if (this.chatbotService?.botConfigurationValues?.enable_disclosure === 'true') {
        this.displayedColumns.push('disclosure');
      }
    } else if (this.feedbackTypes === 'All' && this.currentTabLabel !== 'Pending') {
      this.displayedColumns = [
        'question',
        'response',
        'feedback_comment',
        'additional_comment',
        'username',
        'given_timestamp',
        'language_type',
        'feedback_type',
        'response_type',
        'approveRejectComments',
        // 'approved_rejected_by',
        // 'feedback_flag',
        'model_update_status'
      ];
      if (this.chatbotService?.botConfigurationValues?.enable_disclosure === 'true') {
        this.displayedColumns.push('disclosure');
      }
    }
    else {
      this.displayedColumns = [
        'question',
        'response',
        'feedback_comment',
        'additional_comment',
        'username',
        // 'approved_rejected_by',
        'given_timestamp',
        'language_type',
        'feedback_type',
        'response_type',
        // 'feedback_flag',
        // 'model_update_status',
      ];
    }
    // if (this.currentTabLabel === 'Pending') {
    //   this.displayedColumns.push('action');
    // }
  }

  onFeedbackTypeChange() {
    this.pageNumber = 1;
    this.updateDisplayedColumns();
  }
  ngOnInit() {
    this.feedbackTypes = 'All'
    this.searchFeedback(this.pageNumber, this.pageSize)
    this.updateDisplayedColumns();
  }
  ngAfterViewInit() {
  }
  tabChanged(event: MatTabChangeEvent) {
    this.languageTypeSelected = 'en';
    this.noRecordFound = false;
    this.feedbackTypes = '';
    this.toApproveData = new MatTableDataSource<any>([]);
    this.approvedData = new MatTableDataSource<any>([]);
    this.rejectedData = new MatTableDataSource<any>([]);
    this.pageNumber = 1;
    switch (event.tab.textLabel) {
      case 'Approuvée':
      case 'Approved':
        this.currentTabLabel = 'Approved';
        this.approvalStatus = 'approved';
        this.currentSubTab = 'User Comments';
        this.feedbackTypes = 'All';
        this.languageTypeSelected = 'all'
        this.searchFeedback(this.pageNumber, this.pageSize);
        this.updateDisplayedColumns();
        // Set the active sub-tab programmatically to "All" (index 0)
        // Set the "All" sub-tab for Approved
        if (this.approvedSubTabGroup) {
          this.approvedSubTabGroup.selectedIndex = 0;
        }
        break;
      case 'En attente':
      case 'Pending':
        this.currentTabLabel = 'Pending';
        this.approvalStatus = 'unapproved';
        this.currentSubTab = 'User Comments';
        this.feedbackTypes = 'All';
        this.languageTypeSelected = 'all'
        this.searchFeedback(this.pageNumber, this.pageSize);
        this.updateDisplayedColumns();
        // Set the "All" sub-tab for To Approve
        if (this.toApproveSubTabGroup) {
          this.toApproveSubTabGroup.selectedIndex = 0;
        }
        break;
      case 'Rejetée':
      case 'Rejected':
        this.currentTabLabel = 'Rejected';
        this.approvalStatus = 'rejected';
        this.currentSubTab = 'User Comments';
        this.feedbackTypes = 'All';
        this.languageTypeSelected = 'all'
        this.searchFeedback(this.pageNumber, this.pageSize);
        this.updateDisplayedColumns();
        // Set the "All" sub-tab for Rejected
        if (this.rejectedSubTabGroup) {
          this.rejectedSubTabGroup.selectedIndex = 0;
        }
        break;
      default:
    }

  }
  subTabChanged(event: MatTabChangeEvent) {
    this.pageNumber = 1;
    switch (event.tab.textLabel) {
      case 'Recommandations de cache':
      case 'Cache Recommendations':
        this.languageTypeSelected = 'en'
        this.feedbackTypes = 'Cache Recommendation';
        this.searchFeedback(this.pageNumber, this.pageSize);
        this.updateDisplayedColumns();
        if (this.getSession.get('language') === 'en') {
          this.currentSubTab = 'Cache Recommendation';
        } else {
          this.currentSubTab = 'Recommandations de cache';
        }
        break;
      case 'User Comments':
      case 'Commentaires des utilisateurs':
        this.languageTypeSelected = 'all'
        this.feedbackTypes = 'All';
        this.searchFeedback(this.pageNumber, this.pageSize);
        this.updateDisplayedColumns();
        if (this.getSession.get('language') === 'en') {
          this.currentSubTab = 'User Comments';

        } else {
          this.currentSubTab = 'Commentaires des utilisateurs';
        }
        break;
      default:
    }
  }

  searchFeedback(page_number: number, page_size: number) {
    this.isLoader = true;

    if (this.feedbackTypes === 'Cache Recommendation') {
      const searchPayload = {
        approval_status: this.approvalStatus,
        language_type: this.languageTypeSelected === "all" ? '' : this.languageTypeSelected,
        bot_type: this.getSession.get('bot_type')
      };

      this.contentMgmtService.getCacheRecommendations(searchPayload, page_number, page_size).subscribe(
        (res: any) => this.handleSuccess(res),
        (error: any) => this.handleError(error)
      );
    } else {
      const searchPayload = {
        feedback_type: this.formatFeedbackType(this.feedbackTypes),
        approval_status: this.approvalStatus,
        language_type: this.languageTypeSelected === "all" ? '' : this.languageTypeSelected,
        bot_type: this.getSession.get('bot_type')
      }

      this.contentMgmtService.performUserCommentFeedbackAction(searchPayload, page_number, page_size).subscribe(
        (res: any) => {
          if (this.feedbackTypes !== 'Cache Recommendation') {
            res.data.forEach((data: any) => {
              data.images = []; // Add the 'images' property to each object
            });
          }
          this.handleSuccess(res);
        },
        (error: any) => this.handleError(error)
      );
    }
  }

  private handleSuccess(res: any) {
    this.totalTablePage = res.total_no_of_pages;
    this.isLoader = false;
    switch (this.approvalStatus) {
      case 'unapproved':
        this.toApproveData = new MatTableDataSource<any>(res.data);
        break;
      case 'approved':
        this.approvedData = new MatTableDataSource<any>(res.data);
        break;
      case 'rejected':
        this.rejectedData = new MatTableDataSource<any>(res.data);
        break;
      default:
        throw new Error('Invalid action');
    }
  }

  private handleError(error: any) {
    this.isLoader = false;
    this.noRecordFound = true;
    switch (this.approvalStatus) {
      case 'unapproved':
        this.toApproveData = new MatTableDataSource<any>([]);
        break;
      case 'approved':
        this.approvedData = new MatTableDataSource<any>([]);
        break;
      case 'rejected':
        this.rejectedData = new MatTableDataSource<any>([]);
        break;
      default:
        throw new Error('Invalid action');
    }
    // this.toaster.error(error?.error?.error)
  }

  private newMethod(): string {
    return 'approval_status';
  }

  formatFeedbackType(feedbackType: string): any {
    switch (feedbackType) {
      case 'No Content':
        return 'no_content';
      case 'Sensitive':
        return 'sensitive';
      case 'Normal':
        return 'normal';
      case 'Not Answered':
        return 'not_answered';
      case 'Cache':
        return 'cache';
      case 'Cache Recommendation':
        return 'cache_recommendation';
      case 'All':
        return null;
      default:
        return null;
    }
  }
  formatFeedbackTypeDsiaplay(feedbackType: string): any {
    switch (feedbackType) {
      case 'no_content':
        return 'No Content ';
      case 'sensitive':
        return 'Sensitive';
      case 'normal':
        return 'Normal';
      case 'not_answered':
        return 'Not Answered';
      case 'cache':
        return 'Cache';
      case 'cache_recommendation':
        return 'Cache Recommendation ';
      default:
        return null;
    }
  }

  isApprovedTabSelected(): boolean {
    return this.approvalStatus === 'approved';
  }

  addUserComments() {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        isUserComments: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openSensitiveConfirmationDialog(row: any, index: number, firstDialogRef: MatDialogRef<any>): void {
    console.log("openSensitiveConfirmationDialog called", row, index);
    const title = this.translateLabel('SENSITIVE_CONFIRMATION').translation;
    const message = this.translateLabel('SENSITIVE_CONFIRMATION').translation;

    const dialogRef = this.dialog.open(SensitiveApproveRejectComponent, {
      data: {
        title: title,
        row: row,
        type: "Confirmation",
        message: message,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'ok') {
        this.handleApproval(row);
      } else if (result === 'cancel') {
        this.handleRejection(row);
      }
      firstDialogRef.close();
    });
    /**Accessing approval comments */
    dialogRef.componentInstance.rowApproved.subscribe((approvalData: any) => {
      // Here you can access the emitted object
      this.approveRejectComments = approvalData?.comments;
    });
    /**Accessing reject comments */
    dialogRef.componentInstance.rowRejected.subscribe((rejectedData: any) => {
      // Here you can access the emitted object
      this.approveRejectComments = rejectedData?.comments;
    });

  }

  private handleApproval(row: any): void {
    const approvalPayload = {
      approval_status: 'approved',
      language_type: this.getSession.get('language'),
      bot_type: this.getSession.get('bot_type'),
      username: row?.username,
      feedback_id: row?._id,
      question: row?.question,
      approveRejectComments: this.approveRejectComments
    };

    const apiCall = this.feedbackTypes === 'Cache Recommendation'
      ? this.contentMgmtService.cacheRecommendationApproveReject('approved', {
        cache_id: row?._id,
        ...approvalPayload,
      })
      : this.contentMgmtService.userCommentFeedApproveReject('approved', approvalPayload);

    apiCall.subscribe((res: any) => {
      this.showSuccessMessage();
      this.searchFeedback(this.pageNumber, this.pageSize);
    }, (error: any) => {
      // Handle error if needed
    });
  }

  private handleRejection(row: any): void {
    const rejectionPayload = {
      approval_status: 'rejected',
      language_type: this.getSession.get('language'),
      bot_type: this.getSession.get('bot_type'),
      username: row?.username,
      feedback_id: row?._id,
      question: row?.question,
      approveRejectComments: this.approveRejectComments
    };

    const apiCall = this.feedbackTypes === 'Cache Recommendation'
      ? this.contentMgmtService.cacheRecommendationApproveReject('rejected', {
        cache_id: row?._id,
        ...rejectionPayload,
      })
      : this.contentMgmtService.userCommentFeedApproveReject('rejected', rejectionPayload);

    apiCall.subscribe((res: any) => {
      this.showSuccessMessage();
      this.searchFeedback(this.pageNumber, this.pageSize);
    }, (error: any) => {
      // Handle error if needed
    });
  }

  private showSuccessMessage(): void {
    this.translated.get('successfullMessages.USER_COMMENTS_REJECT_APPROVED_SUCCESS').subscribe((translatedText: string) => {
      this.toaster.success(translatedText);
    });
  }


  // openSensitiveConfirmationDialog(row: any, index: number): void {
  //   const title = this.translateLabel('SENSITIVE_CONFIRMATION_TITLE').translation;
  //   const message = this.translateLabel('SENSITIVE_CONFIRMATION').translation;
  //   const dialogRef = this.dialog.open(SensitiveApproveRejectComponent, {
  //     data: {
  //       title: title,
  //       row: row,
  //       type: "Confirmation",
  //       message: message
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result === 'ok') {
  //       if (this.feedbackTypes === 'Cache Recommendation') {
  //         const approvePayload = {
  //           approval_status: 'approved',
  //           language_type: this.getSession.get('language'),
  //           bot_type: this.getSession.get('bot_type'),
  //           cache_id: row?._id,
  //           username: row?.username
  //         };
  //         this.contentMgmtService.cacheRecommendationApproveReject('approved', approvePayload).subscribe(
  //           (res: any) => {
  //             this.translated.get('successfullMessages.USER_COMMENTS_REJECT_APPROVED_SUCCESS').subscribe((translatedText: string) => {
  //               this.toaster.success(translatedText);
  //             });
  //             this.searchFeedback(this.pageNumber, this.pageSize);
  //           },
  //           (error: any) => {
  //             // Handle error if needed
  //           }
  //         );
  //       } else {
  //         const approveFormData = {
  //           question: row?.question,
  //           bot_type: this.getSession.get('bot_type'),
  //           username: this.getSession.get('username'),
  //           feedback_id: row?._id,
  //           approval_status: 'approved'
  //         }
  //         this.contentMgmtService.userCommentFeedApproveReject('approved', approveFormData).subscribe((res: any) => {
  //           this.translated.get('successfullMessages.USER_COMMENTS_REJECT_APPROVED_SUCCESS').subscribe((translatedText: string) => {
  //             this.toaster.success(translatedText);
  //           });
  //           this.searchFeedback(this.pageNumber, this.pageSize);
  //         }, (error: any) => {
  //           // this.toaster.error(error?.error?.error);
  //         })
  //       }
  //     } else if (result === 'cancel') {
  //       if (this.feedbackTypes === 'Cache Recommendation') {
  //         const rejectPayload = {
  //           approval_status: 'rejected',
  //           language_type: this.getSession.get('language'),
  //           bot_type: this.getSession.get('bot_type'),
  //           cache_id: row?._id,
  //           username: row?.username
  //         };
  //         this.contentMgmtService.cacheRecommendationApproveReject('rejected', rejectPayload).subscribe(
  //           (res: any) => {
  //             this.translated.get('successfullMessages.USER_COMMENTS_REJECT_APPROVED_SUCCESS').subscribe((translatedText: string) => {
  //               this.toaster.success(translatedText);
  //             });
  //             this.searchFeedback(this.pageNumber, this.pageSize);
  //           },
  //           (error: any) => {
  //             // Handle error if needed
  //           }
  //         );
  //       } else {
  //         const rejectFormData = {
  //           question: row?.question,
  //           bot_type: this.getSession.get('bot_type'),
  //           username: this.getSession.get('username'),
  //           feedback_id: row?._id,
  //           approval_status: "rejected"
  //         }
  //         this.contentMgmtService.userCommentFeedApproveReject('rejected', rejectFormData).subscribe((res: any) => {
  //           this.translated.get('successfullMessages.USER_COMMENTS_REJECT_APPROVED_SUCCESS').subscribe((translatedText: string) => {
  //             this.toaster.success(translatedText);
  //           });
  //           this.searchFeedback(this.pageNumber, this.pageSize);
  //         }, (error: any) => {
  //           // this.toaster.error(error?.error?.error);
  //         })
  //       }
  //     }
  //   });
  // }

  showPopover(content: string) {
    if (this.feedbackTypes !== 'Chit Chat' && this.feedbackTypes !== 'Not Answered') {
      const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
        width: 'auto',
        disableClose: true,
        data: {
          isCacheUserResponse: true,
          title: 'User Comments',
          cacheUserResponse: content
        },
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  handleWidthExceedsLimit(isExceeds: any) {
    this.isClickable = isExceeds;
  }
  changeFeedbackOption(value: any) {
    // this.toApproveData = new MatTableDataSource<any>([]);
    // this.approvedData = new MatTableDataSource<any>([]);
    // this.rejectedData = new MatTableDataSource<any>([]);
  }
  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.searchFeedback(pageNumber, this.pageSize)
  }

  isCacheRecommendation(): boolean {
    return this.currentSubTab === 'Cache Recommendation' || this.currentSubTab === 'Recommandations de cache';
  }
  replaceDivsWithParagraphs(input: string): string {
    if (!input) return input; // Return early if the input is null or undefined
    // Regex to remove all <div>, </div>, <p>, and </p> tags
    return input.replace(/<\/?div>/g, '').replace(/<\/?p>/g, '');
  }

  onRowClick(row: string) {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: '100%',
      maxWidth: 'none',
      disableClose: true,
      data: {
        isUserCommentsRow: true,
        userCommentsRow: row,
        currentSubTabLabel: this.currentSubTab,
        currentTabLabel: this.currentTabLabel,
        confirmationDialog: (rowData: any, index: number) => this.openSensitiveConfirmationDialog(rowData, index, dialogRef)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async exportToExcel() {
    console.log('sub tab:', this.currentSubTab);
    let userCmtsPayload = {};
    if (this.currentSubTab === 'User Comments' || this.currentSubTab === 'Commentaires des utilisateurs') {
      userCmtsPayload = {
        "language_type": this.getSession.get('language'),
        "bot_type": this.getSession.get('bot_type'),
        "approval_status": this.approvalStatus,
        "feedback_type": this.feedbackTypes === 'All' ? null : this.feedbackTypes
      }
    } else if (this.currentSubTab === 'Cache Recommendation' || this.currentSubTab === 'Recommandations de cache') {
      userCmtsPayload = {
        "language_type": this.getSession.get('language'),
        "bot_type": this.getSession.get('bot_type'),
        "approval_status": this.approvalStatus,
      }
    }
    (await this.chatTranscriptService.downloadUserComments(userCmtsPayload, this.currentSubTab)).subscribe((res: Blob) => {
      const contentType = res.type;
      if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'User_Comments.csv';
        a.click();
      }
    }, (error: any) => {
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      } else if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      } else if (error.status === 500) {
        this.translated.get('errorMessages.FAILED_DOWNLOAD_ERROR_TRANSCRIPT').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else if (error.status === 404) {
        this.translated.get('errorMessages.REPORT_NOT_DOWNLOADED').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      }
    });
  }
}
