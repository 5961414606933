import { Component, Inject, OnChanges, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../services/localStorageService';
import { ValidatorServiceService } from '../services/validator-service.service';
import { OverlayComponent } from '../pipe/overlay/overlay.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ContentManagementService } from '../services/content-management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordValidator } from '../pipe/password-validator/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../services/chat.service';
import { DateFormatService } from '../pipe/date-time-localize';
import { ImagePreviewService } from '../shared/usr-cmd-preview-image';
export interface DialogData {
  confirmationDialog: (rowData: any, index: number) => void;
  compareData: any;
  transaction_type: string;
  transaction_type_content: string;
  transaction_disclosures: string;
  transaction_notes: string;
  evalData: any;
  runId: any;
  validator: any;
  isValidDisclosure: boolean;
  isValidValidator: boolean;
  isValidEvalData: boolean;
  isValidCompareData: boolean;
  isValidRunIDsData: boolean;
  compareAllData: any[];
  comparingDatas: any[];
  compareDownloadRunId: any[];
  isMismatchField: boolean;
  messageMismatchText: any;
  isDisclosureFrmCache: boolean;
  disclosureFromCache: any[];
  isUserComments: boolean;
  userCommentData: any[];
  isConversationTimout: boolean;
  conversationTimoutMessage: any;
  isCacheUserResponse: boolean;
  cacheUserResponse: any;
  forceLogin: boolean;
  forceLoginMessage: any;
  isPasswordReset: boolean;
  isRemoveProfile: boolean;
  passwordNotValid: boolean;
  passwordNotSamePrevious: boolean;
  isRatingRquiredClicked: boolean;
  title: string;
  isUserCommentsRow: boolean;
  userCommentsRow: any[];
  currentTabLabel: string;
  currentSubTabLabel: string;
  isFeedbackFullRow: boolean;
  feedbackRowDetails: any;
  isCacheQnAFullRow: boolean;
  cacheQnARowDetails: any;
}

interface ResponseData {
  key: string;
  answer_correctness: number;
  answer_relevancy: number;
  context_precision: number;
  context_recall: number;
  faithfulness: number;
}

@Component({
  selector: 'app-disclousre-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class DisclousrePopupModalComponent implements OnInit, OnChanges, OnDestroy {
  feedbackOptionColumns: string[] = [
    'feedback_type',
    'response_type',
    'feedback_cmt_en',
    'feedback_cmt_fr',
    'additional_cmt_req',
    'feedback_flag',
    'data_time'
  ];
  cacheQnAColumns: string [] = [
    'question', 'generated_response', 'images', 'added_by', 'date_time',
  ]
  userCommentsColumns: string[] = [];
  displayedColumns: string[] = [
    'question', 'ground_truth', 'context', 'received_response'
  ];
  displayedCompareColumns: string[] = ['key', 'answer_correctness', 'answer_relevancy', 'context_precision', 'context_recall', 'faithfulness'];
  dataSource: any = [];
  selectedRowCompare: any[] = [];
  comparingDatas: ResponseData[] = [];
  validatorSelectedOption: string = 'csv';
  validatorComOption: string = '';
  isShowFileName: boolean = false;
  fileNameError: boolean = false;
  validatorCmt: string = "";
  evaluationOption: string = '';
  validatorFileName: string = "";
  historyFileName: string = "";
  validatorRunId: string = "";
  validatorOptions: any;
  selectedFile: File | null = null;
  validationSelectedFile: File | null = null;
  fileInputRef: any;
  languageTypes: string[] = ['English', 'French'];
  languageType: string = 'English';
  validatorlanguageType: string = 'English';
  testSizeOptions: string[] = ['1', '2', '3'];
  testSize: string = '';
  compareDataFromValidator: any[] = [];
  compareDbType: any = [];
  compareXlsxType: any = [];
  dbSelectedItems: any = [];
  xlsxSelectedItems: any = [];
  testSizeOption: any;
  testSizeData = ['1', '2', '3', '4'];
  isValidRunIDsData: boolean = false;
  downloadRunIDCompare: any[] = [];
  botLoderType = "boxLoader";
  isLoading = false;
  disclosureFromCache: any = [];
  currentTransction = ''
  private overlayRef: OverlayRef | null = null;
  isModalLoader: boolean = false;
  feedbackTypeOptions: any = ["Sensitive", "No Content", "Normal", "Cache"];
  feedbackTypes: string = 'Sensitive';
  feedbackQuestion: string = '';
  feedbackAnswer: string = '';
  feedbackFlagType: any = false;
  languageTypeSelected: any = "en"
  feedbackFlagTypeOptions = ["True", "False"];
  languageTypeOptions = [
    { label: "English", value: "en" },
    { label: "Français", value: "fr" }
  ];
  changePasswordForm!: FormGroup;
  hideCurrentPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  userCommentsData: any;
  currentSubTabLabel: string = '';
  currentTabLabel: string = '';
  validationOption: any;
  feedbackRowDetails: any;
  cacheQnARowDetails: any;
  constructor(
    private fb: FormBuilder,
    private sessionStorage: LocalStorageService,
    private router: Router,
    public dialogRef: MatDialogRef<DisclousrePopupModalComponent>,
    private overlay: Overlay, private viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private toastr: ToastrService, public validationService: ValidatorServiceService, public dialog: MatDialog, private contentMgmgtService: ContentManagementService,
    private translated: TranslateService, private chatbotservice: ChatbotService, public dateFormat: DateFormatService,
    private imagePreviewService: ImagePreviewService,) {
  }

  ngOnDestroy(): void { }
  ngOnInit() {
    this.dataSource = new MatTableDataSource([this.data?.evalData?.selectedRecordToView[this.data?.evalData?.currentDataIndex]]);
    if (this.data?.compareData?.comparesData?.data) {
      this.getCompareTableData(this.data?.compareData?.comparesData?.data);
      this.downloadRunIDCompare = this.data?.compareData?.comparesData?.data;
    }
    this.validatorOptions = this.data?.validator;
    if (this.data?.disclosureFromCache?.length > 0) {
      this.disclosureFromCache = this.data?.disclosureFromCache;
    }
    if (this.validatorSelectedOption === 'csv') {
      this.evaluationOption = 'conversation';
    }
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordMatchValidator });
    if (this.data?.userCommentsRow) {
      this.userCommentsData = this.data.userCommentsRow;
      this.currentSubTabLabel = this.data.currentSubTabLabel;
      this.currentTabLabel = this.data.currentTabLabel;
    }
    if (this.data?.currentTabLabel === 'Pending' || this.data?.currentTabLabel === 'Approved' || this.data?.currentTabLabel === 'Rejected') {
      this.userCommentsColumns = [
        'question',
        'response',
        'feedback_comment',
        'additional_comment',
        'username',
        'approved_rejected_by',
        'given_timestamp',
        'language_type',
      ];
      // If the sub-tab is 'Cache Recommendation' or its French version, remove 'feedback_comment' and 'additional_comment'
      if (this.currentSubTabLabel === 'Cache Recommendation' || this.currentSubTabLabel === 'Recommandations de cache') {
        // Remove 'feedback_comment' and 'additional_comment' if they exist
        this.userCommentsColumns = this.userCommentsColumns.filter(column =>
          column !== 'feedback_comment' && column !== 'additional_comment'
        );
        this.userCommentsColumns.push('images');
      }
      if (this.data?.currentTabLabel === 'Pending') {
        this.userCommentsColumns.push('action');
      }
    } else {
      this.userCommentsColumns = [
        'question',
        'response',
        'username',
        'approved_rejected_by',
        'given_timestamp',
        'language_type',
        'images',
        // 'model_update_status'
      ]
    }
    if(this.data.isFeedbackFullRow){
      this.feedbackRowDetails = this.data?.feedbackRowDetails;
    }
    if(this.data.isCacheQnAFullRow){
      this.cacheQnARowDetails = this.data?.cacheQnARowDetails;
    }
    this.feedbackTypes = this.feedbackTypeOptions[0];
    this.validationOption = 'validation';
  }
  ngOnChanges() { }

  passwordMatchValidator(form: FormGroup) {
    return form.get('newPassword')?.value === form.get('confirmPassword')?.value
      ? null : { 'mismatch': true };
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.dialogRef.close({
        value: this.changePasswordForm.value
      });
    }
  }

  toggleSelection(item: string) {
    const index = this.compareDbType.indexOf(item);
    if (index > -1) {
      this.compareDbType.splice(index, 1);
    } else {
      this.compareDbType.push(item);
    }
  }
  getCompareTableData(runIDs: any) {
    this.isLoading = true
    const payload =
      { "run_ids": runIDs }
    this.validationService.getCompareData(payload).subscribe((res: any) => {
      this.isLoading = false;
      this.comparingDatas = res;
    }, (error: any) => {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.dialogRef.close('OK');
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  exportToExcel(): void {
    /**this.downloadRunIDCompare data getting from frist modal popup downdown */
    this.validationService.downloadCompareData(this.downloadRunIDCompare).subscribe(
      (downloadResponse: Blob) => {
        const contentType = downloadResponse.type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([downloadResponse], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'average_score.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        this.translated.get('successfullMessages.SUCCESS_FILE_DOWNLOAD_MESSAGE').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
      },
      (error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } else if (error.status === 500) {
          this.translated.get('errorMessages.FAILED_DOWNLOAD_ERROR_TRANSCRIPT').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
        }
      }
    );
  }

  copyContent(textRes: string) {
    const el = document.createElement('textarea');
    el.value = textRes;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  validatorHandleRadioChange() {
    this.validationSelectedFile = null;
    this.validatorFileName = '';
    this.validatorRunId = '';
    this.isShowFileName = false;
    this.historyFileName = '';
    if (this.validatorSelectedOption === 'db') {
      this.evaluationOption = 'bulk';
    }
    if (this.validatorSelectedOption === 'csv') {
      this.evaluationOption = 'conversation';
    }
  }
  validationHandleRadioChange() {
    console.log('this validation:', this.validationOption)
  }
  compareHandleRadioChange() {
  }

  handleFileNameInput(value: string) {
    const valid = /^[a-zA-Z0-9_]*$/.test(value);
    this.fileNameError = !valid;
    if (valid) {
      this.validatorFileName = value;
    }
  }

  showFileName() {
    this.isShowFileName = !this.isShowFileName;
    this.validatorFileName = '';
  }

  backTextboxFileName() {
    this.isShowFileName = !this.isShowFileName;
    this.historyFileName = '';
  }
  formatSize(size: number | undefined): string {
    if (size === undefined) return '';
    const KB = 1024;
    const MB = KB * KB;
    if (size < KB) return size + ' B';
    else if (size < MB) return (size / KB).toFixed(2) + ' KB';
    else return (size / MB).toFixed(2) + ' MB';
  }
  validationHandleFileChange(event: any) {
    // const file = event.target.files[0];
    // this.validationSelectedFile = file;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.validationSelectedFile = input.files[0];
      // Proceed with file handling logic
    } else {
      this.validationSelectedFile = null;
    }
  }
  validationhandleDrop(event: any) {
    // e.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.validationSelectedFile = event.dataTransfer.files[0];
    }
  }
  validationHandleDeleteFile() {
    this.validationSelectedFile = null;
  }
  getFileExtension(fileName: string): any {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1 && lastDotIndex < fileName.length - 1) {
      return fileName.substring(lastDotIndex + 1).toLowerCase();
    }
    return null;
  }
  submitValitor() {
    const formData = new FormData();
    this.isModalLoader = true;
    if (this.validationSelectedFile && this.validatorSelectedOption === 'csv') {
      const fileName = this.validationSelectedFile?.name;
      let fileExtension = this.getFileExtension(fileName);
      formData.append('file_type', fileExtension);
    } else {
      formData.append('file_type', this.validatorSelectedOption);
    }
    formData.append('username', this.sessionStorage.get('username'));
    if (this.validatorSelectedOption === 'csv' && this.validatorFileName !== "")
      formData.append('file_name', this.validatorFileName);
    else
      formData.append('file_name', this.historyFileName);
    formData.append('bot_type', this.sessionStorage.get('bot_type'));
    if (this.validationSelectedFile)
      formData.append('file', this.validationSelectedFile);
    formData.append('language_type', this.validatorlanguageType === "English" ? 'en' : 'fr');
    formData.append('run_id', this.validatorRunId);
    formData.append('comment', this.validatorCmt);
    formData.append('call_type', this.evaluationOption);
    formData.append('validation_type', this.validationOption === 'validation' ? 'true' :  'false')
    if (this.validatorSelectedOption === 'db')
      formData.append('test_size', this.testSize);
    this.validationService.uploadValidation(formData).subscribe((res: any) => {
      this.isModalLoader = false;
      this.translated.get('successfullMessages.VALIDATOR_SAVE_SUCCESS').subscribe((translatedText: string) => {
        this.toastr.success(translatedText);
      });
      this.evaluationOption = ''
      this.validatorSelectedOption = 'csv';
      this.validatorFileName = '';
      this.validatorlanguageType = 'English';
      this.validationSelectedFile = null;
      this.validatorCmt = "";
      this.validatorRunId = "";
      this.dialogRef.close();
    }, (error: any) => {
      this.isModalLoader = false;
      this.onNoClick();
      if (error?.error?.status) {
        this.toastr.error(error?.error?.status);
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    })
  }
  isFormValid(): any {
    const isRunIdValid = this.validatorRunId && this.validatorRunId.length < 15;
    const dbOptionTrue = this.validatorSelectedOption === 'db' && isRunIdValid && this.validatorCmt && this.validatorlanguageType && this.testSize;
    const csvOptionTrue = ((this.validatorFileName && !this.fileNameError && this.validationSelectedFile && this.validatorlanguageType && this.validatorSelectedOption === 'csv' && isRunIdValid && this.validatorCmt) || (this.validatorSelectedOption === 'csv' && this.historyFileName && isRunIdValid && this.validatorCmt && this.validatorlanguageType));
    return dbOptionTrue || csvOptionTrue;
  }
  validateRunID() {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(this.validatorRunId)) {
      this.translated.get('errorMessages.RUNID_ALPHANUMERIC').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    } else if (this.validatorRunId.length > 15) {
      this.translated.get('errorMessages.RUNID_15_CHARACTER').subscribe((translatedText: string) => {
        this.toastr.error(translatedText);
      });
    }
  }

  triggerFileInput() {
    this.fileInputRef?.click();
  }

  sampleFormat() {
    const payload = new FormData();
    payload.append('template', this.evaluationOption);
    this.validationService.downloadSample(payload).subscribe(
      (res: Blob) => {
        const contentType = res.type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([res], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sample_format.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        this.translated.get('successfullMessages.SUCCESS_FILE_DOWNLOAD_MESSAGE').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
      },
      (error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } else if (error.status === 409) {
          this.onNoClick();
        }
      }
    );
  }

  preventDefault(e: any) {
    e.preventDefault();
  }

  showPopover(event: MouseEvent, content: string) {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.clientX, y: event.clientY })
      .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    const popoverPortal = new ComponentPortal(OverlayComponent, this.viewContainerRef);
    const popoverRef = this.overlayRef.attach(popoverPortal);
    popoverRef.instance.content = content;
  }

  hidePopover() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
  validateUserComments() {
    return this.feedbackQuestion && this.feedbackAnswer && this.feedbackTypes && this.feedbackFlagType;
  }
  saveUserFeedback() {
    const savePayload = {
      bot_type: this.sessionStorage.get('bot_type'),
      language_type: this.languageTypeSelected,
      question: this.feedbackQuestion,
      response: this.feedbackAnswer,
      feedback_type: this.formatFeedbackType(this.feedbackTypes),
      response_type: '',
      feedback_flag: this.feedbackFlagType === "True" ? '1' : '0',
      username: this.sessionStorage.get('username')
    }
    this.contentMgmgtService.saveUserFeedback(savePayload).subscribe((res: any) => {
      this.translated.get('successfullMessages.USER_COMMENT_SAVE').subscribe((translatedText: string) => {
        this.toastr.success(translatedText);
      });
      this.dialogRef.close();
    }, (error: any) => {
      // this.toastr.error(error?.error?.error);
    })
  }
  formatFeedbackType(feedbackType: string): string {
    switch (feedbackType) {
      case 'Chit Chat':
        return 'chit_chat';
      case 'No Content':
        return 'no_content';
      case 'Sensitive':
        return 'sensitive';
      case 'Normal':
        return 'normal';
      case 'Not Answered':
        return 'not_answered';
      case 'Cache':
        return 'cache';
      default:
        return '';
    }
  }

  formatFeedbackTypes(feedbackType: string): string {
    switch (feedbackType) {
      case 'none':
        return 'All';
      case 'no_content':
        return 'No Content';
      case 'sensitive':
        return 'Sensitive';
      case 'normal':
        return 'Normal';
      default:
        return '';
    }
  }

  get currentPassword() { return this.changePasswordForm.get('currentPassword'); }
  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get confirmPassword() { return this.changePasswordForm.get('confirmPassword'); }

  getImageKeys(row: any): any[] {
    return this.imagePreviewService.getImageKeys(row);
  }

  previewImage(event: MouseEvent, images: any[], key: string) {
    this.imagePreviewService.previewImage(event, images, key).subscribe(
      (response: any) => {
        const contentType = response.type;
        const blob = new Blob([response], { type: contentType });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      },
      error => {
        this.toastr.error('Error previewing image');
        if (error.status === 401) {
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      }
    );
  }

}
