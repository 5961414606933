import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { LocalStorageService } from './localStorageService';
import { Router, NavigationStart } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { ChatbotService } from './chat.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChatTranscriptService {
  private _transcriptData = new BehaviorSubject<any[]>([]);
  transcriptData$ = this._transcriptData.asObservable();
  transcriptdataLoading = false;
  private chatTranscriptURL = environment.apiBaseUrl['transcriptUrl'];
  private feedbackUrl = environment.apiBaseUrl['feedbackUrl'];
  private getSimilarURL = environment.apiBaseUrl['getSimilarURL'];
  private headers: HttpHeaders = new HttpHeaders();
  private token = '';
  constructor(
    private http: HttpClient,
    private localStroage: LocalStorageService,
    private router: Router,
    private chatbotService: ChatbotService,
    private toastr: ToastrService,
    private translated: TranslateService
  ) {
    //this.updateToken();
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Reset or clear data here
        this._transcriptData.next([]);
      }
    });
  }

  // private updateToken(): void {
  //   this.token = this.localStroage.get('appTo');
  //   this.headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.token}`
  //   });
  // }

  updateTranscriptData(data: any[]) {
    this._transcriptData.next(data);
  }

  getChatTranscriptData(transcriptPayload: any, page_size: any, page: any) {
    //this.updateToken()
    this.transcriptdataLoading = true;
    return this.http.post(`${this.chatTranscriptURL}/user?page_size=${page_size}&page_number=${page}`, transcriptPayload, { headers: this.headers }).pipe(
      map((response: any) => {
        this.transcriptdataLoading = false;
        return response;
      }),
      catchError((error: any) => {
        this.transcriptdataLoading = false;
        return throwError(error);
      })
    );
  }

  async downloadTranscript(formData: any, page_size: number, page: number): Promise<Observable<Blob>> {
    try {
      //this.updateToken();
      return this.http.post(
        `${this.chatTranscriptURL}/user/ods`, formData,
        { headers: this.headers, responseType: 'blob' }
      );
    } catch (error: any) {
      throw error;
    }
  }
  async downloadUserComments(downloadPayload: any, currentSubtab: string): Promise<Observable<Blob>> {
    try {
      //this.updateToken();
      let userCmtURL = ''
      if (currentSubtab === 'User Comments' || currentSubtab === 'Commentaires des utilisateurs') {
        userCmtURL = `${this.feedbackUrl}/downloadUserFeedback`
      } else if (currentSubtab === 'Cache Recommendation' || currentSubtab === 'Recommandations de cache') {
        userCmtURL = `${this.getSimilarURL}/cacheDownload`
      }
      return this.http.post(
        userCmtURL, downloadPayload,
        { headers: this.headers, responseType: 'blob' }
      );
    } catch (error: any) {
      throw error;
    }
  }
}

