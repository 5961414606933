import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { LocalStorageService } from './localStorageService';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { StateService } from './shared-object.service';
import { TranslateServiceSpecifiedLabelsService } from './translate-service-specified-labels.service';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

interface ChatHistoryItem {
  type: 'User' | 'Chatbot';
  content: string;
  timestamp: string;
}
@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  // Create a Subject to emit loader events
  private loaderSubject = new Subject<boolean>();

  // Expose the loader subject as an observable
  loaderState$ = this.loaderSubject.asObservable();

  static logout() {
    throw new Error('Method not implemented.');
  }
  // Initialize the BehaviorSubject with an empty array of ChatHistoryItem
  private chatHistorySubject: BehaviorSubject<ChatHistoryItem[]> = new BehaviorSubject<ChatHistoryItem[]>([]);
  // Expose chatHistory$ as an Observable of ChatHistoryItem array
  public chatHistory: Observable<ChatHistoryItem[]> = this.chatHistorySubject.asObservable();
  public authAPIUrl = environment.apiBaseUrl['authService'];
  public chatAPIURL = environment.apiBaseUrl['chatbotURL'];
  public getSimilarURL = environment.apiBaseUrl['getSimilarURL'];
  public getCurrentHistoryURL = environment.apiBaseUrl['chatHistoryURL'];
  public contentMgmtURL = environment.apiBaseUrl['contentMgmtURL'];
  public configServiceURL = environment.apiBaseUrl['configServiceURL'];
  public feedbackURL = environment.apiBaseUrl['feedbackUrl'];

  // public headers: any;
  greetingMessage: string = '';
  initialConversationID: string = '';
  responseTime: string = '';
  userId: string = '';
  apiResponse: SafeHtml = '';
  inputDisabled: any = false;
  botRespponse: any;
  languageType: string | undefined;
  pref_lang_type: any = '';
  profilePictureUrl: SafeUrl = '';
  pref_lang__in_chat: boolean = false;
  ratingFeedbackComments: any = [];
  feedbackResponseFromAPI: any[] = [];
  feedbackIconDisabled: boolean = false;
  cacheHyperlinkClicked: boolean = false;
  stepWiseHyperlinkClicked: boolean = false;
  botCurrentResponse: any;
  botCurrentQuestion: any;
  private token = '';
  private headers: HttpHeaders = new HttpHeaders();
  validaSessionActive: boolean = false;
  // validatorOptionsDropdown : any;
  botConfigurationValues: any;
  streamingAugData: any;
  constructor(private http: HttpClient, private localStroage: LocalStorageService, private sanitizer: DomSanitizer, private router: Router, private toastr: ToastrService, public sharedService: StateService
    , private translateService: TranslateServiceSpecifiedLabelsService, public dialog: MatDialog,
    private translated: TranslateService
  ) {
    //this.updateToken();
    this.pref_lang_type = this.localStroage.get('language');
  }

  // private updateToken(): void {
  //   this.token = this.localStroage.get('appTo');
  //   this.headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.token}`
  //   });
  // }
  async sendMessage(message: string, lang_pref: string, stepWiseobject: any) {
    let response;
    let chatHistory = this.chatHistorySubject.getValue();
    this.getAppBotConfiguration();
    const apiCallWithTimeout = (promise: Promise<any>, ms: number) => {
      let timeout = new Promise((_, reject) => setTimeout(() => reject(new Error("timeout")), ms));
      return Promise.race([promise, timeout]);
    };

    try {
      if (stepWiseobject === 'stepwiseCallTrue') {
        response = await apiCallWithTimeout(this.chatbotServiceResponse(message, lang_pref, stepWiseobject), 300000); // 5 Min timeout
        this.apiResponse = response?.response;
        if (this.apiResponse != undefined) {
          chatHistory.push({ type: 'Chatbot', content: `${this.apiResponse}`, timestamp: this.getFormattedUTCTime() });
        }
      } else if (stepWiseobject === 'stepwiseCallFalse') {
        chatHistory.push({ type: 'User', content: message, timestamp: this.getFormattedUTCTime() });
        const isStreamURL = this.botConfigurationValues.enable_chat_stream === 'true' ? 'stream' : 'chatservice';
        console.log('isStreamURL:', isStreamURL);
        if (isStreamURL === 'stream') {
          response = await this.chatbotServiceResponse(message, lang_pref, stepWiseobject);
          console.log('response:', response);
          if (response?.response || response?.response === "") {
            this.commonHandlingBotresponse(response);
          }
        } else {
          response = await apiCallWithTimeout(this.chatbotServiceResponse(message, lang_pref, stepWiseobject), 300000); // 5 min timeout
          this.botRespponse = response;
          this.commonHandlingBotresponse(response);
          // switch (this.botRespponse?.responseType) {
          //   case "cibc-questions":
          //   case "small-talk":
          //   case "cache":
          //     this.apiResponse = response?.response;
          //     break;
          //   case "no-content":
          //     this.apiResponse = `<div>${this.translateLabel('OUT_OF_SCOPE_RES_TEXT').translation}</div>`;
          //     break;
          //   case "not-answered":
          //     this.apiResponse = `<div>${this.translateLabel('NO_CONTENT_RES_TEXT').translation}</div>`;
          //     break;
          //   case "language_mismatch_french":
          //     this.apiResponse = `<div>${this.translateLabel('LANGUAGE_MISMATCH_FR').translation}</div>`;
          //     break;
          //   case "language_mismatch_english":
          //     this.apiResponse = `<div>${this.translateLabel('LANGUAGE_MISMATCH_EN').translation}</div>`;
          //     break;
          //   case "sensitive-question":
          //     this.apiResponse = `<div>${this.translateLabel('SENSITIVE_RES_TEXT').translation}</div>`;
          //     break;
          //   case "PII":
          //     this.apiResponse = `<div>${this.translateLabel('PII_RES_TEXT').translation}</div>`;
          //     break;
          //   default:
          //     this.apiResponse = `<div>${this.translateLabel('CHATBOT_UNDEFIND_TEXT').translation}</div>`;
          // }
          // if (this.apiResponse != undefined) {
          //   chatHistory.push({ type: 'Chatbot', content: `${this.apiResponse}`, timestamp: this.getFormattedUTCTime() });
          // }
        }
      }
    } catch (error: any) {
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error?.status === 519) {
        this.botRespponse = [];
        chatHistory.push({ type: 'Chatbot', content: `<div>${error?.error?.error}`, timestamp: this.getFormattedUTCTime() });
        const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
          width: 'auto',
          disableClose: true,
          data: {
            isConversationTimout: true,
            conversationTimoutMessage: error?.error?.error
          },
        });

        dialogRef.afterClosed().subscribe(result => {
        });
      } else {
        chatHistory.push({ type: 'Chatbot', content: `<div>${this.translateLabel('CHATBOT_UNDEFIND_TEXT').translation}</div>`, timestamp: this.getFormattedUTCTime() });
        chatHistory.push({ type: 'Chatbot', content: `<div>${this.translateLabel('START_NEW_CONVERSATION_MESSAGE').translation}</div>`, timestamp: this.getFormattedUTCTime() });
      }
    } finally {
      this.chatHistorySubject.next(chatHistory);
      this.sharedService.sendData(true);
      // this.inputDisabled = false;
    }

    return response;
  }
  commonHandlingBotresponse(response: any) {
    this.botRespponse = response;
    switch (this.botRespponse?.responseType) {
      case "cibc-questions":
      case "small-talk":
      case "cache":
        this.apiResponse = response?.response;
        break;
      case "no-content":
        this.apiResponse = `<div>${this.translateLabel('OUT_OF_SCOPE_RES_TEXT').translation}</div>`;
        break;
      case "not-answered":
        this.apiResponse = `<div>${this.translateLabel('NO_CONTENT_RES_TEXT').translation}</div>`;
        break;
      case "language_mismatch_french":
        this.apiResponse = `<div>${this.translateLabel('LANGUAGE_MISMATCH_FR').translation}</div>`;
        break;
      case "language_mismatch_english":
        this.apiResponse = `<div>${this.translateLabel('LANGUAGE_MISMATCH_EN').translation}</div>`;
        break;
      case "sensitive-question":
        this.apiResponse = `<div>${this.translateLabel('SENSITIVE_RES_TEXT').translation}</div>`;
        break;
      case "PII":
        this.apiResponse = `<div>${this.translateLabel('PII_RES_TEXT').translation}</div>`;
        break;
      default:
        this.apiResponse = `<div>${this.translateLabel('CHATBOT_UNDEFIND_TEXT').translation}</div>`;
    }
    if (this.apiResponse != undefined) {
      let chatHistory = this.chatHistorySubject.getValue();
      chatHistory.push({ type: 'Chatbot', content: `${this.apiResponse}`, timestamp: this.getFormattedUTCTime() });
    }
  }
  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.pref_lang_type || this.localStroage.get('language');
    const translation = this.translateService.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
  clearChatHistory() {
    this.chatHistorySubject.next([]); // Set an empty array to clear the chat history
  }

  async login(payload: any) {
    try {
      const response = await this.http.post(`${this.authAPIUrl}/login`, payload).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async initialChatResponse(payload: any): Promise<any> {
    //this.updateToken();
    try {
      this.getAppBotConfiguration();
      const response = await this.http.post(`${this.chatAPIURL}/chatservice`, payload, { headers: this.headers }).toPromise();
      this.sharedService.sendData(false)
      this.initialConversationID = (response as any)?.conversationID;
      this.localStroage.set('conversationID', (response as any)?.conversationID)
      this.userId = (response as any)?.userID;
      this.languageType = (response as any)?.language_type;
      this.greetingMessage = (response as any)?.greetings || 'Hi, I am CIBC Contact Centre Assistant. How I can help you today?';
      const lang = this.languageType || this.localStroage.get('language'); // Default to English if language is not provided
      const additionalMessage = this.translateService.translateLabel('ADDITIONAL_MESSAGE_LABEL', lang);
      const chatHistory = this.chatHistorySubject.getValue();
      this.inputDisabled = false;
      chatHistory.push({ type: 'Chatbot', content: `${this.greetingMessage}`, timestamp: this.getFormattedUTCTime() });
      // const additionalMessage = 'Please note that this chatbot is an AI-based assistant designed to provide information and answer questions related to CIBC Investor Bank products and services. It is not intended to offer professional financial or investment advice. ';
      chatHistory.push({ type: 'Chatbot', content: additionalMessage, timestamp: this.getFormattedUTCTime() });
      // Emit the updated chatHistory to subscribers
      this.chatHistorySubject.next(chatHistory);
      return response;
    } catch (error: any) {

      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      throw error;
    }
  }

  async chatbotServiceResponse(messagePayload: any, lang: string, stepWiseobject: any) {
    const payload = this.buildPayload(messagePayload, lang, stepWiseobject);
    const jwtToken = this.localStroage.get('appTo');
    this.getAppBotConfiguration();

    const isStreamURL = this.botConfigurationValues.enable_chat_stream === 'true' ? 'stream' : 'chatservice';

    if (isStreamURL === 'stream') {
      return this.handleStreamingResponse(`${this.chatAPIURL}/${isStreamURL}`, payload, jwtToken);
    } else {
      return this.handleStandardResponse(`${this.chatAPIURL}/${isStreamURL}`, payload, jwtToken);
    }
  }

  private buildPayload(messagePayload: any, lang: string, stepWiseobject: any): any {
    return {
      "conversationID": this.initialConversationID,
      "language_type": lang,
      "query": messagePayload,
      "userId": this.userId,
      "procedural_resp": stepWiseobject === 'stepwiseCallTrue',
      "bot_type": this.localStroage.get('bot_type')
    };
  }

  private handleStreamingResponse(url: string, payload: any, jwtToken: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const headers = {
        'Authorization': `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      };
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        });
    
        // Handle both non-streaming and streaming response types
        if (response.ok) {
          const contentType = response.headers.get('Content-Type');
    
          // Check if the response is JSON and handle non-streaming responses
          if (contentType && contentType.includes('text/html')) {
            const textResponse = await response.text();
    
            // Attempt to parse the text as JSON (since content may still be JSON)
        try {
          const parsedResponse = JSON.parse(textResponse);
          // console.log('Parsed JSON from text response:', parsedResponse);

          // Handle non-streaming response (e.g., cache, no-content)
          if (['small-talk', 'cache', 'no-content', 'not-answered', 'sensitive-question', 'language_mismatch_french', 'language_mismatch_english', 'PII'].includes(parsedResponse.responseType)) {
            this.loaderSubject.next(false);
            resolve(parsedResponse); // Resolve with non-streaming response
            return;
          }

        } catch (jsonParseError) {
          console.error('Failed to parse text response as JSON:', jsonParseError);
          reject('Invalid JSON in response.');
          return;
        }
            // Streaming response case
          } else if (response.body && response.body.getReader) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedResponse = '';
            let lastChunkWithAugdata = null;
    
            // Signal the start of the loader
            this.loaderSubject.next(true);
    
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
    
              const chunk = decoder.decode(value, { stream: true });
              accumulatedResponse += chunk;
    
              // Process streaming chunks
              try {
                const jsonChunks = accumulatedResponse.split('\n').filter(line => line).map(line => JSON?.parse(line));
                const fullContent = jsonChunks.map(chunkData => chunkData.content).join('');
    
                // Update chat UI with the combined response so far
                this.updateChatUI(fullContent);
    
                // Handle any 'stop' and augment data
                jsonChunks.forEach(chunkData => {
                  if (chunkData.finish_reason === 'stop' && chunkData.augdata) {
                    lastChunkWithAugdata = chunkData.augdata;
                  }
                });
    
                if (lastChunkWithAugdata) {
                  resolve(lastChunkWithAugdata);
                  this.botRespponse = lastChunkWithAugdata;
                  this.apiResponse = lastChunkWithAugdata;
                }
    
                // Stop loader once the stream ends
                this.loaderSubject.next(false);
              } catch (error) {
                console.error('Error parsing streaming chunk:', error);
              }
            }
          }
        } else {
          reject(new Error(`HTTP Error: ${response.status}`));
        }
      } catch (error) {
        console.error('Error during request:', error);
        reject(error);
      }
    });
    
  }
  // Helper function to check if a string is valid JSON
  isValidJSON(data: any) {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  }
  private updateChatUI(content: string) {
    let chatHistory = this.chatHistorySubject.getValue();

    // If there’s already a bot message in the history, update its content
    const lastMessage = chatHistory[chatHistory.length - 1];
    if (lastMessage && lastMessage.type === 'Chatbot') {
      lastMessage.content = content;
    } else {
      // Otherwise, add a new bot message
      chatHistory.push({ type: 'Chatbot', content, timestamp: this.getFormattedUTCTime() });
    }

    // Update the chat history
    this.chatHistorySubject.next(chatHistory);
  }

  private async handleStandardResponse(url: string, payload: any, jwtToken: string): Promise<any> {
    //this.updateToken();
    try {
      const response = await this.http.post(url, payload, { headers: this.buildHeaders(jwtToken) }).toPromise();
      this.sharedService.sendData(true);
      return response;
    } catch (error: any) {
      this.handleHttpError(error);
      throw error;
    }
  }


  private handleHttpError(error: any): void {
    if (error.status === 409 || error.status === 401) {
      this.handleAuthError(error.status);
    }
  }

  private handleAuthError(statusCode: number): void {
    const errorMessageKey = statusCode === 409
      ? 'errorMessages.LOGIN_CURRENT_SESSION_EXPIRED'
      : 'errorMessages.UNAUTHORIZED_ERROR';

    this.translated.get(errorMessageKey).subscribe((translatedText: string) => {
      this.toastr.error(translatedText);
    });

    this.router.navigate(['/login']);
    sessionStorage.clear();
  }

  private buildHeaders(jwtToken: string): { [key: string]: string } {
    return {
      'Authorization': `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    };
  }

  chatbotConversationEnd(endConvPayload: any) {
    //this.updateToken();
    try {
      const response = this.http.post(`${this.chatAPIURL}/endchat`, endConvPayload, { headers: this.headers }).toPromise();
      this.pref_lang__in_chat = false;
      return response;
    } catch (error: any) {
      // console.log('error:error:', error);
      throw error;
    }
  }
  getFormattedUTCTime(): string {
    // Get the current UTC time
    const currentUtcTime = new Date();

    // Format the UTC time as a string in 12-hour format with AM/PM indicator
    const formattedTime = currentUtcTime.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return formattedTime;
  }

  saveUserFeedback(userFeedback: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.chatAPIURL}/feedback`, userFeedback, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getSimiliarQuestion(similarPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getSimilarURL}/getSimilarQuestions`, similarPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  responseToAddToCache(toCachePayload: any) {
    //this.updateToken();
    return this.http.post(`${this.getSimilarURL}/to_cache_recommendation`, toCachePayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getQuestionAutoComplete(questionPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getSimilarURL}/getSuggestions`, questionPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getCureentWeekChatHistory(currentWeekPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getCurrentHistoryURL}/current_week_last`, currentWeekPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getCureentWeekSelectedHistory(currentWeekSelectedPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getCurrentHistoryURL}/current_week`, currentWeekSelectedPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getCureentMonthhatHistory(currentMonthPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getCurrentHistoryURL}/current_month_last`, currentMonthPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getCureentMonthSelectedHistory(currentMonthSelectedPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getCurrentHistoryURL}/current_month`, currentMonthSelectedPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  async forgotPassword(email: any) {
    let forgotPayload = new FormData();
    forgotPayload.append('username', email);
    forgotPayload.append('bot_type', 'cibc');
    try {
      const response = await this.http.post(`${this.authAPIUrl}/forgotpwd`, forgotPayload).toPromise();
      return response;
    } catch (error: any) {

      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      throw error;
    }
  }

  async uploadProfilePicture(payload: any) {
    try {
      //this.updateToken();
      const response = await this.http.post(`${this.authAPIUrl}/upload_dp`, payload, { headers: this.headers }).toPromise();
      return response;
    } catch (error: any) {

      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      throw error;
    }
  }


  saveLanguage(languagePayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.authAPIUrl}/update_language`, languagePayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getFeedbackList(feedbackPayload: any) {
    const token = this.localStroage.get('appTo');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(`${this.feedbackURL}/get_response_feedback`, feedbackPayload, { headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  saveConversationFeedbac(feedbackPayload: any) {
    //this.updateToken();
    return this.http.post(`${this.chatAPIURL}/conversationFeedback`, feedbackPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  getActiveConversationFromDB(converID: any) {
    //this.updateToken();
    return this.http.get(`${this.chatAPIURL}/conversation-availability?conversation_id=${converID}`, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  getDisclosureDetails(questionPayload: any): Observable<any> {
    //this.updateToken();
    return this.http.post(`${this.getSimilarURL}/getDisclosureSearch`, questionPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  validateSession(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.authAPIUrl}/logout`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  async logout() {
    const logoutPayload = {
      username: this.localStroage.get('username'),
      bot_type: this.localStroage.get('bot_type')
    }
    try {
      const logout = await this.validateSession(logoutPayload).toPromise();
      if (logout) { }
    } catch (error: any) {
      this.validaSessionActive = false;
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.localStroage.clear();
        this.router.navigate(['/login']);
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.localStroage.clear();
        this.router.navigate(['/login']);
      }
    }
  }
  async removeProfilePicture(payload: any) {
    try {
      //this.updateToken();
      const response = await this.http.post(`${this.authAPIUrl}/remove_dp`, payload, { headers: this.headers }).toPromise();
      return response;
    } catch (error: any) {

      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      throw error;
    }
  }

  validateValidSession(): void {
    //this.updateToken();
    this.http.get(`${this.authAPIUrl}/auth`, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    ).subscribe(
      (response: any) => {
        this.validaSessionActive = true;
      },
      (error: any) => {
        this.validaSessionActive = false;
      }
    );
  }

  changePassword(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.authAPIUrl}/change_password`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    )
  }

  getAppBotConfiguration() {
    //this.updateToken();
    return this.http.get(`${this.configServiceURL}/chatBotUIConfig/getbotConfigurations/cibc`, { headers: this.headers }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ).subscribe((res: any) => {
      this.botConfigurationValues = res;
    }, (error: any) => {
      // console.log('error in getAppBotConfiguration:', error);
    })
  }

  saveReorder(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.feedbackURL}/feedbackReorder`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

}


