import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserManagementService } from "../../app/services/user-management.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation-service';
import { LocalStorageService } from '../services/localStorageService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { NgModel, ValidatorFn, AbstractControl, ValidationErrors, FormControl, Validators } from '@angular/forms';
import { passwordValidator } from '../pipe/password-validator/custom-validators';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { ProfileImageService } from '../pipe/removeProfileImageService';
import { ChatbotService } from '../services/chat.service';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
const USER_FORM_REQ_ERROR_KEY = 'errorMessages.USER_FORM_REQ_ERROR';
const VALID_EMAIL_ERROR_KEY = 'errorMessages.VALID_EMAIL_ERROR';
const USER_EXIST_EORROR_KEY = 'errorMessages.USER_EXIST_EORROR';
const SEARCH_NOT_FOUND_KEY = 'errorMessages.SEARCH_NOT_FOUND';
const ENTER_SEARCH_TEXT_KEY = 'errorMessages.ENTER_SEARCH_TEXT';
const PASSWORD_CR_TEXT_KEY = 'errorMessages.PASSWORD_CR_TEXT';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {
  @ViewChild('userNameInput') userNameInput!: ElementRef;
  @ViewChild('firstNameInput') firstNameInput!: ElementRef;
  @ViewChild('lastNameInput') lastNameInput!: ElementRef;
  @ViewChild('email') email!: NgModel;
  @ViewChild('password') password!: NgModel;
  @ViewChild('firstname') firstname!: NgModel;
  @ViewChild('lastname') lastname!: NgModel;
  @ViewChild('role') role!: NgModel;
  profilePictureUrl: SafeUrl = '';
  rolesArray: any[] = ["botadmin", "chatanalyst", "contentmanager", "configadmin", "evaluator", "user", "useradmin"];
  availableRoles: string[] = ["botadmin", "chatanalyst", "contentmanager", "configadmin", "evaluator", "user", "useradmin"];
  searchQuery: string = '';
  editedItem: any = {};
  data: any = [];
  editMode: boolean[] = [];
  roles: string[][] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  totalTablePage: number = 0;
  user: any;
  selectedFile: File | undefined;
  isNewUser: boolean = false;
  firstName = '';
  lastName = '';
  httpClient: any;
  isLoader = false;
  translate: any;
  botLoderType = 'boxLoader'
  isMobileView: boolean = false;
  allowedDomain = 'cibc.com';
  emailControl = new FormControl('', [this.emailDomainValidator()]);
  passwordControl = new FormControl('', [Validators.required, passwordValidator()]);
  isEditEnabled: boolean = false;
  
  constructor(
    private userMgmtService: UserManagementService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private router: Router,
    translateService: TranslationService,
    private sessionStorage: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    private translated: TranslateService,
    public removesProfileImage: ProfileImageService,
    public chatbotService: ChatbotService,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService
  ) {
    this.translate = translateService;
  }
  ngOnInit(): void {
    this.getAllUsers(1);
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileView = result.matches;
      });
  }

  onFileSelected(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];
      this.removesProfileImage.removedProfile = false;
    }
  }

  getAllUsers(pageNumber: any) {
    this.isLoader = true;
    this.userMgmtService.getAllUsers(pageNumber, this.pageSize).subscribe((userResponse: any) => {
      this.isLoader = false;
      this.totalTablePage = userResponse.total_no_of_pages;
      this.data = userResponse.all_users.map((user: any) => ({
        ...user,
        profilePictureUrl: (user.profile_picture !== '' && user.profile_picture !== null) ?
          this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${user.profile_picture}`) :
          'assets/noImage.png',
      }));
      this.initializeEditMode();
    }, (error: any) => {
      this.isLoader = false;
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        this.sessionStorage.clear();
      }
    })
  }
  initializeEditMode() {
    this.editMode = this.data.map(() => false);
  }
  addNewRow() {
    const newRow = { profilePictureUrl: null, username: '', roles: [] };
    // Add the new row to the beginning of the data array
    this.data.unshift(newRow);
    // Set edit mode for the new row to true
    this.editMode.unshift(true);
    this.editMode.push(true);
    this.isNewUser = true;
    this.selectedFile = undefined;
  }

  handleEditClick(item: any, index: number) {
    if(this.selectedFile?.name){
      this.selectedFile = undefined;
    }
    this.editMode[index] = !this.editMode[index];
    this.isEditEnabled = true;
    this.roles[index] = item.roles;
    if (!this.isNewUser) {
      this.isNewUser = false;
    }

  }

  cancelEditMode(row: any, index: number){
    this.isEditEnabled = false;
    this.editMode[index] = !this.editMode[index];
    this.getAllUsers(this.pageNumber);
  }

  async getBlobFromURL(url: string): Promise<Blob> {
    const response = await this.httpClient.get(url, { responseType: 'blob' }).toPromise();
    return response;
  }

  private validateUserForm(item: any): boolean {
    return item.username !== "" && this.firstName !== '' && this.lastName !== '' &&
      (this.roles && Array.isArray(this.roles) && this.roles.length > 0);
  }

  private showErrorMessage(errorKey: string): void {
    this.translated.get(errorKey).subscribe((translatedText: string) => {
      this.toaster.error(translatedText);
    });
  }

  private handleNewUser(item: any, index: number): void {
    if (this.validateUserForm(item)) {
      this.saveUser(item, index);
    } else {
      this.showErrorMessage(USER_FORM_REQ_ERROR_KEY);
    }
  }

  handleSaveChanges(item: any, index: number): void {
    if (this.isNewUser) {
      this.handleNewUser(item, index);
    } else {
      this.updateUser(item, index);
    }
  }

  updateUser(item: any, index: number) {
    const formData = new FormData();
    // if(this.chatbotService.profilePictureUrl === 'assets/noImage.png' && !this.removesProfileImage.removedProfile){
    //   this.selectedFile = undefined
    // }
    if (this.selectedFile instanceof File) {
      formData.append("new_profile_picture", this.selectedFile);
    }
    else {
      formData.append("new_profile_picture", "");
    }
    formData.append("current_username", item.username);
    const rolesString = this.roles[index].join(', ');
    formData.append("roles", rolesString);
    formData.append("bot_type", this.sessionStorage.get('bot_type'));
    this.userMgmtService.performUserAction('updateuser', formData).subscribe((response: any) => {
      
      this.translated.get('successfullMessages.UPDATED_USER_SUCCESS').subscribe((translatedText: string) => {
        this.toaster.success(translatedText);
      });
      this.getAllUsers(1);
      this.pageNumber = 1;
      this.isNewUser = false;
      this.isEditEnabled = false;
    }, (error: any) => {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        this.sessionStorage.clear();
      } else if (error.status === 400) {
        if (error?.error?.error === 'Invalid image format.') {
          if (this.selectedFile instanceof File) {
          this.selectedFile = undefined;
        }
          this.translated.get('errorMessages.INVALID_IMG_FORMAT').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
        }
      }
    });
  }

  saveUser(item: any, index: number) {
    const formData = new FormData();
    if (this.selectedFile) {
      formData.append("profile_picture", this.selectedFile);
    }
    else {
      formData.append("profile_picture", "");
    }
    formData.append("username", item.username);
    const rolesString = this.roles[index]?.join(', ');
    formData.append("roles", rolesString);
    formData.append("firstname", this.firstName);
    formData.append("lastname", this.lastName);
    formData.append("bot_type", this.sessionStorage.get('bot_type'));
    formData.append("password", item.password);
    this.userMgmtService.performUserAction('adduser', formData).subscribe((response: any) => {
      this.translated.get('successfullMessages.ADD_USER_SUCCESS').subscribe((translatedText: string) => {
        this.toaster.success(translatedText);
      });
      this.editMode[index] = false;
      this.getAllUsers(1);
      this.pageNumber = 1;
      this.isNewUser = false;
      this.selectedFile = undefined;
      this.firstName = '';
      this.lastName = '';
      this.roles = [];
      this.isEditEnabled = false;
    }, (error: any) => {

      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      } else if (error.status === 404) {
        this.showErrorMessage(USER_EXIST_EORROR_KEY);
      } else if (error.status === 400) {
        if (error?.error?.message === 'Current password cannot be same as previous.') {
          const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
            width: 'auto',
            disableClose: true,
            data: {
              passwordNotSamePrevious: true,
            },
          });

          dialogRef.afterClosed().subscribe(result => {
          });
        } else if (error?.error?.message === 'New password does not meet the required criteria') {
          const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
            width: 'auto',
            disableClose: true,
            data: {
              passwordNotValid: true,
            },
          });

          dialogRef.afterClosed().subscribe(result => {
          });
        }
      }
    });
  }
  handleDelete(item: any, i: number) {
    this.openConfirmationDialog(item)
  }


  searchTableData() {
    if (this.searchQuery !== "") {
      this.isLoader = true;
      const payloadSearch = { "username": this.searchQuery, "bot_type": this.sessionStorage.get('bot_type') }
      this.userMgmtService.performUserAction('searchuser', payloadSearch).subscribe((response: any) => {
        this.isLoader = false;
        this.data = response?.matching_user_list?.map((user: any) => ({
          ...user,
          profilePictureUrl: (user.profile_picture !== '' && user.profile_picture !== null) ?
            this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${user.profile_picture}`) :
            'assets/noImage.png',
        }));
      }, (error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        } else {
          this.showErrorMessage(SEARCH_NOT_FOUND_KEY);
        }
      })
    } else {
      this.showErrorMessage(ENTER_SEARCH_TEXT_KEY);
    }
  }

  clearSearch() {
    this.pageNumber = 1;
    if (this.searchQuery !== "") {
      this.searchQuery = "";
      this.getAllUsers(1);
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.info(translatedText);
      });
    }
  }

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.getAllUsers(pageNumber)
  }
  openConfirmationDialog(selectedRow: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const messageDelete: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL} ${selectedRow.username}`);
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1,
        message: messageDelete,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const phraseDeletePayload = {
          "username": selectedRow.username,
          "bot_type": this.sessionStorage.get('bot_type')
        }
        this.userMgmtService.performUserAction('deleteuser', phraseDeletePayload).subscribe((response: any) => {
          this.translated.get('successfullMessages.DELEETE_SUCCESS').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.getAllUsers(1);
          this.pageNumber = 1;
          this.searchQuery = '';
        }, (error: any) => {

          if (error.status === 409) {
            this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
        });
      }
    });
  };
  handleRolesChange(selectedRoles: string[], index: number) {
    // Clear the existing roles for the current index
    this.roles[index] = [];

    // Iterate through the selected roles and add them to the roles array for the current index
    selectedRoles.forEach((selectedRole: string) => {
      this.roles[index].push(selectedRole);
    });
  }
  cancelAddRow() {
    if (this.userNameInput) {
      this.userNameInput.nativeElement.value = '';
    }
    if (this.firstNameInput) {
      this.firstNameInput.nativeElement.value = '';
    }
    if (this.lastNameInput) {
      this.lastNameInput.nativeElement.value = '';
    }

    this.firstName = '';
    this.lastName = '';
    if (this.isNewUser) {
      this.data.shift();
      this.editMode.shift();
    }
    this.isNewUser = false;
  }
  checkEmailValid(email: NgModel) {
    const emailPattern = /^(?=.{5,60}$)(?!.*\.\.)(?!.*@.*@)[a-zA-Z0-9._%-]+@(cibc\.com|cibc\.co|kumaran\.com|kumaran\.world)$/i;

    // Create a custom validator
    const domainValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      return emailPattern.test(control.value) ? null : { invalidEmail: true };
    };

    // Get the existing validator if any
    const existingValidator = email.control.validator;

    // Combine existing validators with the new one
    const combinedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const existingError = existingValidator ? existingValidator(control) : null;
      const domainError = domainValidator(control);
      return existingError || domainError;
    };

    // Set the combined validator
    email.control.setValidators(combinedValidator);
    email.control.updateValueAndValidity();
  }

  emailDomainValidator(): ValidatorFn {
    const emailPattern =/^(?=.{5,60}$)(?!.*\.\.)(?!.*@.*@)[a-zA-Z0-9._%-]+@(cibc\.com|cibc\.co|kumaran\.com|kumaran\.world)$/i;
    return (control: AbstractControl): ValidationErrors | null => {
      return emailPattern.test(control.value) ? null : { invalidEmail: true };
    };
  }

  
   removeProfileImage(row: any) {
   this.removesProfileImage.removeProfileImage(row);
  }
  isSaveDisabled(): any {
    return this.email?.invalid || this.password?.invalid ||
      this.firstname?.invalid || this.lastname?.invalid ||
      this.role?.invalid;
  }

  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
}
