<div>
    <div class="padding-60 flex-center-content" *ngIf="!isDesktop">
        <div class="toggle-option full-width" (click)="handleChangeAlignment('chat')"
            [ngClass]="{'active': selectedOption === 'chat'}">
            {{'chatBot.CHAT' | translate}}
        </div>
        <div class="toggle-option full-width" (click)="handleChangeAlignment('fqas')"
            [ngClass]="{'active': selectedOption === 'fqas'}">
            {{'chatBot.SIM_QUESTION_TEXT' | translate}}
        </div>
    </div>

    <div *ngIf="selectedOption ==='chat'"
        [ngClass]="{'desktop-chatbot-container': isDesktop, 'mobile-chatbot-container': !isDesktop}" #contentRef>
        <ng-container
            *ngFor="let message of chatbotService.chatHistory | async;let first = first; let index = index; let last= last">
            <div
                [ngClass]="{'single-row-user': message.type === 'User' , 'single-row-chatbot': message.type === 'Chatbot' }">
                <span *ngIf="message.type === 'Chatbot'">
                    <img height="30px" alt='Bot Avatar' src="assets/bot icon.png" />
                </span>
                <div class="message" [ngClass]="{ 'user': message.type === 'User', 'bot': message.type === 'Chatbot' }">
                    <ng-container *ngIf="message.type === 'User'">
                        {{ message.content }}
                    </ng-container>
                    <ng-container *ngIf="message.type === 'Chatbot'">
                        <div [innerHTML]="message.content"></div>
                        <ng-container
                            *ngIf="chatbotService && chatbotService?.botRespponse && chatbotService?.botRespponse?.responseType && chatbotService?.botRespponse?.responseType !== 'small-talk' && chatbotService?.botRespponse?.responseType !== 'not-answered' && !first && index != 1  && last && chatbotService?.botConfigurationValues?.enable_image; else smallTalkTemplate">
                            <div *ngIf="imageKeys.length> 0 && imageUrls.length> 0">
                                <div class="key-display-container">
                                    <div>{{'chatBot.IMAGE_TEXT' | translate}}</div>

                                    <div class="arrow" (click)="showPreviousKey()" [class.disabled]="currentIndex === 0"
                                        [attr.disabled]="currentIndex === 0 ? true : null">
                                        <mat-icon>keyboard_arrow_left</mat-icon>
                                    </div>
                                    <div class="key-name"> <a href="#" target="_blank"
                                            (click)="imagePreview($event, previewUrl)">{{ currentKey }}</a>
                                    </div>
                                    <div class="arrow" (click)="showNextKey()"
                                        [class.disabled]="currentIndex === imageKeys.length - 1"
                                        [attr.disabled]="currentIndex === imageKeys.length - 1 ? true : null">
                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-rating-section">
                                <div fxLayout="row" fxLayoutGap="10" fxLayoutAlign="start center">
                                    <ng-container
                                        *ngIf="chatbotService?.botRespponse?.responseType !== 'small-talk' && chatbotService?.botRespponse?.responseType !== 'not-answered' && chatbotService?.botConfigurationValues?.enable_feedback === 'true' && chatbotService?.botRespponse?.responseType !== 'PII'">

                                        <span class="align-item-center"
                                            *ngIf="chatbotService?.botRespponse?.responseType === 'cache'">{{
                                            translateLabel('ANS_FROM_CACHE').translation}}</span>
                                        <span class="align-item-center"
                                            *ngIf="chatbotService?.botRespponse?.responseType !== 'cache'">{{
                                            translateLabel('RATING_TAG_TEXT').translation
                                            }}</span>
                                        <span class="feedbak-img-style"
                                            [class.disabled-img]=" chatbotService.feedbackIconDisabled"
                                            (click)="isAutoRatingIconEnabled ? posstiveRate() : null">
                                            <img height="15" alt="Like Up" src="assets/like 1.svg" />
                                        </span>
                                        <span class="feedbak-img-style"
                                            [class.disabled-img]=" chatbotService.feedbackIconDisabled"
                                            (click)="isUserRatingIconEnabled ? rating() : null">
                                            <img height="15" alt="Like down" src="assets/like 2.svg" />
                                        </span>
                                    </ng-container>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10" fxLayoutAlign="end center">
                                    <ng-container
                                        *ngIf="chatbotService && chatbotService?.botRespponse?.queryInfo && chatbotService?.botRespponse?.queryInfo?.length > 0  && chatbotService?.botRespponse?.responseType !== 'small-talk' && chatbotService?.botRespponse?.responseType !== 'no-content' && chatbotService?.botConfigurationValues?.enable_augumented_content ==='true'">
                                        <span class="feedbak-img-style cursor"
                                            (click)="handleModalPopup(chatbotService.botRespponse)">
                                            <img height="15" src="assets/tag.svg" alt="Tag" />
                                        </span>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="chatbotService?.botRespponse?.responseType === 'cibc-questions' && chatbotService?.botConfigurationValues?.enable_stepwise_response ==='true'">
                                        <div (click)="handleStepwiseHyperlink()"
                                            [class.disable-cache]="chatbotService.stepWiseHyperlinkClicked"
                                            class="align-item-center custom-link">
                                            {{ translateLabel('STEPWISE_TEXT').translation }}
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="chatbotService?.botRespponse?.responseType === 'cibc-questions' &&  chatbotService?.botConfigurationValues?.enable_tocache">
                                        <div (click)="handleCacheHyperlink()"
                                            [class.disable-cache]="chatbotService.cacheHyperlinkClicked"
                                            class="align-item-center custom-link">
                                            {{ translateLabel('TOCACHE_TEXT').translation }}
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="hasValues(chatbotService?.botRespponse?.disclosure_details) && chatbotService?.botRespponse?.disclosure_required ==='true' && (chatbotService?.botRespponse?.responseType === 'cibc-questions' || chatbotService?.botRespponse?.responseType === 'cache') && chatbotService?.botConfigurationValues?.enable_disclosure ==='true'">
                                        <div (click)="handleDisclosure()" class="align-item-center custom-link">
                                            {{ translateLabel('DISCLOSURE').translation }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </ng-container>
                        <ng-template #smallTalkTemplate></ng-template>
                    </ng-container>
                </div>
                <span *ngIf="message.type === 'User'">
                    <img [src]="userProfile" alt="Profile Picture" height="30" class="profile-border-radius50" />
                </span>
            </div>
            <div [ngClass]="{'single-row-user': message.type === 'User' , 'single-row-chatbot': message.type === 'Chatbot' }"
                class="padding-left-bottm time-font-size">{{message.timestamp.toLocaleUpperCase()}}</div>
        </ng-container>
        <div class="center-alignment" *ngIf="isLoader">
            <app-loader [botLoderType]="botLoderType"></app-loader>
        </div>
        <div class="input-container chat-input" *ngIf="isResponseReceived">
            <div class="row-input">
                <span (click)="openConfirmationDialog()" class="cursor">
                    <img height="20px" alt="Language" src="assets/language.svg" />
                </span>
                <div class="width-100 input-wrapper" (click)="onInputClick()">
                    <input [(ngModel)]="userInput" (ngModelChange)="changingInput($event)"
                        [disabled]="chatbotService && chatbotService.inputDisabled" (keyup.enter)="sendMessage()"
                        placeholder="{{'chatBot.INPUT_PLACEHOLDER' | translate}}" />
                </div>
                <span (click)="userInput !== '' && sendMessage()" [class.disabled-img]="validationError"
                    [class.cursor]="userInput !== ''">
                    <img height="18px" [class.grayscale-img]="userInput === ''" alt="Bot Avatar"
                        src="assets/send.svg" />
                </span>
            </div>
            <!-- <div class="error-message"*ngIf="validationError">{{ validationError }}</div> -->
            <!-- Add the dropdown menu for autocomplete suggestions -->
            <div *ngIf="showSuggestions" class="autocomplete-container">
                <ul>
                    <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion); sendMessage()">{{
                        suggestion }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="sidenav-right" *ngIf="isDesktop && selectedOption==='chat'; else mobileView">

        <app-right-drawer-similar-questions [qaResponses]="qaResponses"></app-right-drawer-similar-questions>
    </div>
    <ng-template #mobileView>
        <!-- Display content within a div -->
        <app-right-drawer-similar-questions *ngIf="selectedOption ==='fqas'"
            [qaResponses]="qaResponses"></app-right-drawer-similar-questions>
    </ng-template>
</div>


<app-rating-modal [showModal]="showModalRating" *ngIf="showModalRating"
    (closeModalEvent)="onCloseModal($event)"></app-rating-modal>