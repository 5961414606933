<div class="page-containter" [style.paddingLeft.px]="isMobileView? 2:5" [style.paddingRight.px]="isMobileView? 2:0">
    <div class="table-content-fit-page">
        <div *ngIf="transcriptService.transcriptdataLoading; else dataLoaded" class="loading-bar-center">
            <!-- Loader -->
            <app-loader [botLoderType]="botLoderType"></app-loader>
        </div>
        <div class="no-data-message" *ngIf="!transcriptService.transcriptdataLoading && (!transcriptData || transcriptData.length === 0)">
            {{'contentMgmt.NO_RECORD_FOUND' | translate}}
        </div>
        <ng-template #dataLoaded>
            <div class="table-container">
                <table class="custom-tables" *ngIf="transcriptData && transcriptData.length > 0">
                    <thead>
                        <tr>
                            <th>{{'sideNav.EMAIL' | translate}}</th>
                            <th>{{'chatBot.CONVERSATION_TEXT' | translate}}</th>
                            <th>{{'chatBot.CONVERSATION_DATE_TIME' | translate}}</th>
                            <th>{{'chatBot.VIEW' | translate}}</th>
                        </tr>
                    </thead>
                    <!-- Table Body -->
                    <tbody>
                        <tr *ngFor="let conversation of transcriptData;  let index = index"
                            [class.even-row]="index % 2 === 0" [class.odd-row]="index % 2 !== 0">
                            <td>
                                <span><img height="12px" alt='User Email' src='assets/account.svg' /></span>
                                {{conversation.userID }}
                            </td>
                            <td>
                                <div>
                                    <p *ngFor="let question of conversation.conversation">{{ question.question }}
                                    </p>
                                </div>
                            </td>
                            <td class="text-nowrap">{{
                                dateFormatService.convertUtcToLocalUnderScore(conversation.startTime) }}</td>
                            <td><img alt='Kumaran Systems Private Limited' class="cursor"
                                    (click)="openDrawer(conversation)" src='assets/eye 1.png' /></td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="pagination" *ngIf="transcriptData && transcriptData.length > 0">
                <button (click)="onPageChange(1)" [disabled]="currentPage === 1">
                    <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
                </button>
                <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">
                    <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
                </button>
                <span>{{ currentPage }} / {{ totalTablePages }}</span>
                <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalTablePages">
                    <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
                </button>
                <button (click)="onPageChange(totalTablePages)" [disabled]="currentPage === totalTablePages">
                    <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
                </button>
                <span class="export-excel-style" (click)="exportToExcel()">
                    <mat-icon aria-hidden="false" aria-label="Download" fontIcon="file_download"></mat-icon>
                </span>
            </div>
            <app-conversation-drawer [selectedConversation]="selectedConversation"
                (drawerClosed)="handleDrawerClosed()"></app-conversation-drawer>
            <!-- No records found message -->

        </ng-template>
        <!-- <p class="no-data-message"></p> -->
    </div>
    <div *ngIf="isModalLoader" class="modal-backdrop">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>