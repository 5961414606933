import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ChatTranscriptService } from "../services/chat-transcript.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateService } from "../services/shared-object.service";
import { Subscription } from 'rxjs';
import { TranslationService } from '../services/translation-service';
import { LocalStorageService } from '../services/localStorageService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateFormatService } from '../pipe/date-time-localize';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../services/chat.service';

@Component({
  selector: 'app-conversation-history',
  templateUrl: './conversation-history.component.html',
  styleUrls: ['./conversation-history.component.scss']
})
export class ConversationHistoryComponent implements OnInit, OnChanges {
  @Input() transcriptData: any[] = [];
  @ViewChild('TABLE')
  table!: ElementRef;
  loading: boolean = false;
  selectedConversation: any;
  currentPage: number = 1;
  totalTablePages = 0;
  pageSize: number = 20;
  email: string = '';
  startDate: any = '';
  endDate: any = '';
  searchData: any;
  private downloadSubscription: Subscription | undefined;
  transcriptDataSubscription: Subscription | undefined;
  transcriptDataSearchData: Subscription | undefined;
  translate: any;
  botLoderType = 'boxLoader'
  isMobileView: boolean = false;
  isModalLoader: boolean = false;
  constructor(
    public transcriptService: ChatTranscriptService,
    private router: Router,
    private toastr: ToastrService,
    private stateSharedService: StateService,
    translateService: TranslationService,
    private sessionStorage: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    public dateFormatService: DateFormatService,
    private translated: TranslateService,
    private chatbotService: ChatbotService
  ) {
    this.translate = translateService;
  }

  ngOnInit() {
    this.updateLoadingState();
    this.transcriptDataSubscription = this.transcriptService.transcriptData$.subscribe(data => {
      let totalPages = data.pop();
      if (totalPages?.totalPages !== undefined) {
        this.totalTablePages = totalPages?.totalPages;
      }
      this.transcriptData = [...data];
      this.updateLoadingState();
    });

    this.transcriptDataSearchData = this.stateSharedService.searchData$.subscribe(data => {
      this.searchData = data;
    });
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileView = result.matches;
      });
  }


  onSearch(searchCriteria: { email: string, startDate: string, endDate: string, page: number, pageSize: number, startTime: number, endTime: number }) {
    this.transcriptData = [];
    this.email = searchCriteria.email;
    this.startDate = searchCriteria.startDate;
    this.endDate = searchCriteria.endDate;
    const startDate = new Date(this.startDate);
    startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());

    const endDate = new Date(this.endDate);
    endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const searchData = {
      user_string: this.email.toLowerCase(),
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
      bot_type: this.sessionStorage.get('bot_type'),
      language_type: this.sessionStorage.get('language'),
      start_hour: null,
      timezone_offset: timeZone,
      end_hour: null,
    }
    this.transcriptService.getChatTranscriptData(searchData, this.pageSize, this.currentPage).subscribe((transcriptResponse) => {
      let totalPages = transcriptResponse.pop();
      this.totalTablePages = totalPages?.totalPages;
      this.transcriptData = transcriptResponse;
      this.updateLoadingState();
    }, (error) => {
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    })
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.onSearch({ email: this.searchData.email, startDate: this.searchData.startDate, endDate: this.searchData.endDate, page: this.currentPage, pageSize: this.pageSize, startTime: this.searchData.startTime, endTime: this.searchData.endTime });
  }

  exportToExcel(): void {
    this.downloadTranscript({ email: this.searchData.email, startDate: this.searchData.startDate, endDate: this.searchData.endDate, page: this.currentPage, pageSize: this.pageSize, startTime: this.searchData.startTime, endTime: this.searchData.endTime });
  }

  async downloadTranscript(searchCriteria: { email: string, startDate: any, endDate: any, page: number, pageSize: number, startTime: number, endTime: number }) {

    this.isModalLoader = true;
    const { email, startDate, endDate, page, pageSize, startTime, endTime } = searchCriteria;

    // const formattedStartDate = this.dateFormatService.convertUtcToLocal24HRS(startDate);

    // const formattedEndDate = this.dateFormatService.convertUtcToLocal24HRS(endDate);
    const formattedStartDate = new Date(startDate);
    formattedStartDate.setMinutes(formattedStartDate.getMinutes() - formattedStartDate.getTimezoneOffset());

    const formattedEndDate = new Date(endDate);
    formattedEndDate.setMinutes(formattedEndDate.getMinutes() - formattedEndDate.getTimezoneOffset());

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let bodyFormData = {
      user_string: email,
      start_date: formattedStartDate.toISOString().split('T')[0],
      end_date: formattedEndDate.toISOString().split('T')[0],
      bot_type: this.sessionStorage.get('bot_type'),
      timezone_offset: timeZone,
      language_type: this.sessionStorage.get('language'),
      start_hour: startTime,
      end_hour: endTime
    }
    this.downloadSubscription = (await this.transcriptService.downloadTranscript(bodyFormData, pageSize, page))
      .subscribe(
        (downloadResponse: Blob) => {
          this.isModalLoader = false
          const contentType = downloadResponse.type;
          console.log('contentType:', contentType);
          if (contentType === 'text/csv') {
            const blob = new Blob([downloadResponse], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'chat_transcript.csv'; // Set the desired filename
            //   a.addEventListener('click', () => {
            //     // Display toaster message after the click event
            //     this.toastr.success('File has been downloaded successfully');
            // });
            a.click();
          }
        },
        (error: any) => {
          this.isModalLoader = false;
          if (error.status === 409) {
            this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 500) {
            this.translated.get('errorMessages.FAILED_DOWNLOAD_ERROR_TRANSCRIPT').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
          }
        }
      );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.transcriptDataSubscription) {
      this.transcriptDataSubscription.unsubscribe();
    }
    if (this.transcriptDataSearchData) {
      this.transcriptDataSearchData.unsubscribe();
    }
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.updateLoadingState();
  }

  private updateLoadingState() {
    if (this.transcriptData.length === 0) {
      this.loading = false;
    }
  }
  openDrawer(conversation: any): void {
    this.selectedConversation = conversation;
    // Open the drawer
    this.selectedConversation.drawerOpen = true;
  }

  handleDrawerClosed(): void {
    // Update any necessary state in the parent component
    // For example, clear selectedConversation or any other relevant action
    this.selectedConversation = null;
  }

}
