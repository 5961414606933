import { Component, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from './services/translation-service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChatbotService } from './services/chat.service';
import { LocalStorageService } from './services/localStorageService';
import { StateService } from './services/shared-object.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CIBC Chatbot';
  translate: any;
  events: string[] = [];
  opened: boolean = false;
  platform = "";
  mode: any
  isMobileScreen: boolean = false;
  constructor(private router: Router, translateService: TranslationService,
    private breakpointObserver: BreakpointObserver, private chatbotService: ChatbotService, private sessionStorage: LocalStorageService, private stateService: StateService, private toastr: ToastrService,
    private translated: TranslateService,
    private _snackBar: MatSnackBar,) {
    this.translate = translateService;
    this.breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.platform = !result.matches ? 'desktop' : 'mobile';
      console.log(this.platform);
      if (this.platform === 'desktop') {
        this.isMobileScreen = false;
        this.opened = false;
        this.mode = 'over'
      } else if (this.platform === 'mobile') {
        this.isMobileScreen = true;
        this.opened = false;
        this.mode = 'over'
      }
    });
    // Update the mode dynamically when click menu's
    this.stateService.currentMode.subscribe(newMode => {
      this.mode = newMode;
    });
  }

  // Function to check if the current route is the login page
  isLoginPage(): boolean {
    return this.router.url === '/login';
  }
  isForgotPasswordPage(): boolean {
    return this.router.url === '/forgotpassword';
  }
  isChatbotPage(): boolean {
    return this.router.url === '/chatbot'
  }
  isConversationHistoryPage(): boolean {
    return this.router.url === '/conversationhistory'
  }
  isContentManagementPage(): boolean {
    return this.router.url === '/contentmanagement'
  }
  isPasswordResetPage(): boolean {
    return this.router.url.includes('/passwordreset');
  }
  isThankYouPage(): boolean {
    return this.router.url === '/thankyou';
  }
  isSynonymsPage(): boolean {
    return this.router.url === '/synonyms';
  }
  isConfigurationPage(): boolean {
    return this.router.url === "/apiconfiguration"
  }
  isUserManagementPage(): boolean {
    return this.router.url === "/usermanagement"
  }
  isFeedbackPage(): boolean {
    return this.router.url === "/feedbackcomment"
  }

  isCacheTopicPage(): boolean {
    return this.router.url === "/cache"
  }
  isConfigTopicPage(): boolean {
    return this.router.url === "/configuration"
  }
  isChatHistoryPage(): boolean {
    return this.router.isActive("/chathistory", false);
  }
  isValidationPage(): boolean {
    return this.router.url === "/validation"
  }
  isChitChatPage() {
    return this.router.url === "/addchitchat"
  }
  isUserComments() {
    return this.router.url === "/user-comments"
  }
  isreportsPage() {
    return this.router.url === "/reports"
  }
  /**
   * toggle drawer open and clos based on click menu
   */
  toggleDrawerMode(): void {
    this.opened = !this.opened;
  }
  /**
   * 
   * @param action side nav action will trigger from app-chatsidenav component
   */
  handleActionClick(action: any): void {
    if (this.mode === 'over')
      this.toggleDrawerMode();
  }
  newConversation() {
    this.chatbotService.inputDisabled = false;
    if (this.chatbotService.botRespponse) {
      this.stateService.clearDashboard();
      this.stateService.setDisableInput(false);
      this.chatbotService.chatbotConversationEnd({ "conversationID": this.sessionStorage.get('conversationID'), "boy_type": this.sessionStorage.get('bot_type') }).then((response) => {
        this.translated.get('successfullMessages.END_CONVERSATION_SUCCESS').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
        this.chatbotService.clearChatHistory();
        this.chatbotService.botCurrentResponse = {};
        this.chatbotService.botCurrentQuestion = "";
        this.chatbotService.pref_lang_type = this.sessionStorage.get('language')
        const initialChatpaload = {
          "query": "Hi",
          "userId": this.sessionStorage.get('username'),
          "bot_type": this.sessionStorage.get('bot_type'),
          "language_type": this.sessionStorage.get('language')
        }
        this.chatbotService.initialChatResponse(initialChatpaload);

      }).catch((error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      })
    }
    // this.router.navigate(['/chatbot']);
    // this.translated.get('sideNav.NEW_CONVO_MSG').subscribe((message: string) => {
    //   this._snackBar.open(message, 'OK', {
    //        duration: 5000,
    //        verticalPosition: 'top',
    //        horizontalPosition: 'center',
    //        panelClass: ['custom-snackbar'],
    //        data: { offset: 50 },
    //      });
    //    })
  }

  isNonLoginPage(): boolean {
    return !this.isLoginPage() && !this.isThankYouPage() && !this.isPasswordResetPage() && !this.isForgotPasswordPage();
  }
  
  isImportantPage(): boolean {
    return this.isUserComments() || this.isValidationPage() || this.isConfigurationPage() || 
           this.isContentManagementPage() || this.isFeedbackPage() || this.isCacheTopicPage() || 
           this.isUserManagementPage() || this.isChatbotPage() || this.isChatHistoryPage() || this.isreportsPage();
  }
  
}
