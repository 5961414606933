import { Component, Input } from '@angular/core';
import { ChatbotService } from '../services/chat.service';
import { LocalStorageService } from '../services/localStorageService';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StateService } from '../services/shared-object.service';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-right-drawer-similar-questions',
  templateUrl: './right-drawer-similar-questions.component.html',
  styleUrls: ['./right-drawer-similar-questions.component.scss']
})
export class RightDrawerSimilarQuestionsComponent {
  isDesktop = false;
  constructor(public chatbotService: ChatbotService,
    private sessionStorage: LocalStorageService,
    private toastr: ToastrService,
    private router: Router,
    private stateService: StateService,
    private translated: TranslateService,
    private breakpointObserver: BreakpointObserver,){
      /**Method Used to find desktop/ Mobile device */
    this.breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isDesktop = !result.matches;
    });
    }
  @Input()
  qaResponses!: any[];

  isChatbotUrl(): boolean {
    return this.router.url.includes('/chatbot');
  }
  newConversation() {
    this.chatbotService.inputDisabled = false;
    if (this.chatbotService.botRespponse) {
      this.stateService.clearDashboard();
      this.stateService.setDisableInput(false);
      this.chatbotService.chatbotConversationEnd({ "conversationID": this.sessionStorage.get('conversationID'), "boy_type": this.sessionStorage.get('bot_type') }).then((response) => {
        this.translated.get('successfullMessages.END_CONVERSATION_SUCCESS').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
        this.chatbotService.clearChatHistory();
        this.chatbotService.botCurrentResponse = {};
        this.chatbotService.botCurrentQuestion = "";
        this.chatbotService.pref_lang_type = this.sessionStorage.get('language')
        const initialChatpaload = {
          "query": "Hi",
          "userId": this.sessionStorage.get('username'),
          "bot_type": this.sessionStorage.get('bot_type'),
          "language_type": this.sessionStorage.get('language')
        }
        this.chatbotService.initialChatResponse(initialChatpaload);

      }).catch((error: any) => {
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
      })
    }
  }
}
