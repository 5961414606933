<div class="page-containter" [style.paddingLeft.px]="isMobileView? 2:5" [style.paddingRight.px]="isMobileView? 2:0">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="10"
        fxLayout.lt-md="column" *ngIf="!isNewRow && !editModeEnabled">
        <div fxFlex="50%" fxFlex.xs="100%">
            <mat-form-field fxFlex="100%">
                <mat-label>{{'devConfig.APPLICATION_NAME' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedApplication" (ngModelChange)="filterDataByApplication()">
                    <mat-option *ngFor="let appName of applicationNames" [value]="appName">{{ appName }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="50%" fxFlex.xs="100%">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="10"
                fxLayoutAlign="end center" fxLayout.lt-md="column">
                <button mat-button class="mui-button" (click)="addNewRow()">{{'devConfig.ADD_NEW_APP' |
                    translate}}</button>
                <mat-form-field appearance="fill">
                    <mat-label>{{'sideNav.SEARCH' | translate}}</mat-label>
                    <input matInput #searchInput [(ngModel)]="searchQuery"
                        placeholder="{{'devConfig.ENTER_FOR'| translate}}" (keydown.enter)="searchTableData()">
                    <button mat-icon-button matSuffix (click)="searchTableData()">
                        <mat-icon>search</mat-icon>
                    </button>
                    <button mat-icon-button matSuffix (click)="clearSearch()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

    </div>

    <div fxLayout.lt-md="column" *ngIf="isNewRow" class="table-content-fit-page">
        <div class="padding-10">
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'devConfig.NEW_APPLICATION_NAME' | translate}}</mat-label>
                <input matInput name="newApplicationInput" [(ngModel)]="newApplicationValue"
                    placeholder="{{'devConfig.BUTTON_PLACEHOLDER' | translate}}" required
                    #newApplicationInput="ngModel">
                <mat-error
                    *ngIf="newApplicationInput.invalid && (newApplicationInput.dirty || newApplicationInput.touched)">
                    {{ 'errorMessages.CONFIG_APP_NAME_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'devConfig.KEY' | translate}}</mat-label>
                <input matInput name="newKeys" [(ngModel)]="newKey" placeholder="{{'devConfig.KEY' | translate}}"
                    required #newKeys="ngModel">
                <mat-error *ngIf="newKeys.invalid && (newKeys.dirty || newKeys.touched)">
                    {{ 'errorMessages.CONFIG_KEY_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'devConfig.VALUE' | translate}}</mat-label>
                <input matInput name="newValues" [(ngModel)]="newValue" placeholder="{{'devConfig.VALUE' | translate}}"
                    required #newValues="ngModel">
                <mat-error *ngIf="newValues.invalid && (newValues.dirty || newValues.touched)">
                    {{ 'errorMessages.CONFIG_VALUE_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width-100-percent">
                <mat-label>{{'devConfig.DESCRIPTION_LABEL' | translate}}</mat-label>
                <textarea matInput [(ngModel)]="description"
                    placeholder="{{'devConfig.DESCRIPTION_LABEL' | translate}}"></textarea>
            </mat-form-field>
            <div fxLayoutAlign="end end" fxLayoutGap="20">
                <button mat-button class="mui-button-clear" (click)="clear()">{{'devConfig.CANCEL' |
                    translate}}</button>
                <button mat-button class="mui-button"
                    [disabled]="newApplicationInput.invalid || newKeys.invalid || newValues.invalid"
                    (click)="addNewRecord()">{{'sideNav.ADD' |
                    translate}}</button>
            </div>
        </div>
    </div>
    <div class="table-content-fit-page" *ngIf="!isNewRow">
        <table class="custom-tables">
            <div class="padding-left15">
                <div *ngIf="!editModeEnabled">
                    <button mat-button class="mui-button" [disabled]="isNewRow"
                        (click)="addConfiguration()">{{'devConfig.ADD_CONFIG' | translate}}</button>
                </div>
            </div>
            <br>
            <thead>
                <tr>
                    <th>{{'devConfig.KEY' | translate}}</th>
                    <th>{{'devConfig.VALUE' | translate}}</th>
                    <th>{{'devConfig.DESCRIPTION_LABEL' | translate}}</th>
                    <th>{{'devConfig.ACTION' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="data.length > 0; else loaderSection">
                    <tr *ngFor="let row of data; let index = index" [class.even-row]="index % 2 === 0"
                        [class.odd-row]="index % 2 !== 0">
                        <td>
                            <mat-form-field appearance="outline" class="width-100-percent">
                                <input matInput #keyInput [(ngModel)]="row.key" [disabled]="shouldDisableKey(index)"
                                    [placeholder]="getPlaceholderKey(index)">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" class="width-100-percent">
                                <textarea matInput #valueInput [(ngModel)]="row.value" [disabled]="!editMode[index]"
                                    [placeholder]="getPlaceholderValue(index)"></textarea>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" class="width-100-percent">
                                <textarea matInput #descriptionInput [(ngModel)]="row.config_description"
                                    [disabled]="!editMode[index]" [placeholder]="getPlaceholderDescription(index)"
                                    (blur)="setDefaultIfEmpty(row, 'config_description')"></textarea>
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="action-even-space">
                                <span *ngIf="row.key === '' || row.value === ''">
                                    <img src="assets/close.svg" class="icon-button cursor" height="15" alt="Close"
                                        (click)="removeRow(index)">
                                </span>
                                <ng-container *ngIf="editMode[index]; then editModeEnabled else editModeDisabled">

                                </ng-container>
                                <ng-template #editModeEnabled>
                                    <button *ngIf="editModeEnabled" mat-button class="mui-button-clear"
                                        (click)="cancelEditMode(index)">{{'devConfig.CANCEL' |
                                        translate}}</button>
                                    <img src="assets/icons8-save-30.png" class="icon-button cursor" height="20"
                                        alt="Save Icon" (click)="handleSaveChanges(row, index)">
                                </ng-template>
                                <ng-template #editModeDisabled>
                                    <img src="assets/pen 1.svg" class="icon-button cursor" height="20" alt="Edit Icon"
                                        (click)="handleEditClick(row, index)" [class.disabled]="disableIcon">
                                </ng-template>
                                <span>
                                    <img src="assets/trash 1-black.svg" class="icon-button cursor" height="20"
                                        alt="Delete" (click)="handleDelete(row, index)" [class.disabled]="disableIcon">
                                </span>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #loaderSection>
                    <td colspan="8" *ngIf="isLoader">
                        <div class="loader-center-align">
                            <app-loader [botLoderType]="botLoderType"></app-loader>
                        </div>
                    </td>
                    <td colspan="8" *ngIf="data.length === 0 && !isLoader" class="center-align">{{'sideNav.NO_DATA' |
                        translate}}</td>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>