import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private clearDashboardSubject = new Subject<void>();
  clearDashboard$ = this.clearDashboardSubject.asObservable();

  dataSubject = new Subject<any>();
  private searchDataSubject = new Subject<any>();
  searchData$ = this.searchDataSubject.asObservable();

  private disableInputSource = new BehaviorSubject<boolean>(false);
  disableInput$ = this.disableInputSource.asObservable();

  private confirmationSubject = new Subject<boolean>();

  confirmationResult$ = this.confirmationSubject.asObservable();

  confirmAction(result: boolean) {
    this.confirmationSubject.next(result);
  }
  
   // Create a BehaviorSubject to store and observe the sidenav mode
   private sidenavMode = new BehaviorSubject<string>('over');
  
   // Expose an observable for other components to subscribe to
   currentMode = this.sidenavMode.asObservable();

   // Function to change the sidenav mode
  changeSidenavMode(mode: string) {
    this.sidenavMode.next(mode);
  }

 

  constructor() { }

  clearDashboard(): void {
    this.clearDashboardSubject.next();
  }
  
  emitSearchData(data: any) {
    this.searchDataSubject.next(data);
  }

  sendData(data: any) {
    this.dataSubject.next(data);
  }
  
  getData() {
    return this.dataSubject.asObservable();
  }

  setDisableInput(value: boolean): void {
    this.disableInputSource.next(value);
  }
  
}
