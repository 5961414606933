<!-- Side Navigation -->
<div class="sidenav" [style.height.vh]="isMobile? 100: 100" (click)="closeProfileMenu()">
    <div class="top-section">
        <div class="chat-history-container" *ngIf="isChatBotChatHistory() && botType !=='cibc'"
            [style.maxHeight.px]="maxHeight" [style.height.px]="height">
            <div class="chat-history-style">
                <div class="chat-history-head-style">
                    {{'sideNav.CURRENT_WEEK' | translate}}
                    <mat-icon class="accordion-icon cursor" (click)="toggleAccordion('currentWeek')">{{
                        isCollapsed('currentWeek') ? 'expand_more' : 'expand_less' }}</mat-icon>
                </div>
                <div *ngIf="!isCollapsed('currentWeek')" class="chat-list">
                    <div *ngIf="chatCurrentData.length > 0; else noData">
                        <div class="list-style" *ngFor="let chat of chatCurrentData; let i = index"
                            [class.selected]="isItemSelected(i)" (click)="selectItem(chat, i, 'currentWeek')">
                            {{ dateFormatService.convertUtcToLocalUnderScore(chat.startTime) }}
                        </div>
                    </div>
                    <ng-template #noData>
                        <div *ngIf="isLoadingWeek" fxLayout="row" fxLayoutAlign="center center" fxFlexAlign="center">
                            <div class="loader"></div>
                        </div>
                        <div *ngIf="!isLoadingWeek" fxLayout="row" fxLayoutAlign="center center">
                            <div>{{'sideNav.NO_DATA' | translate}}</div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="chat-history-style">
                <div class="chat-history-head-style">
                    {{'sideNav.CURRENT_MONTH' | translate}}
                    <mat-icon class="accordion-icon cursor" (click)="toggleAccordion('currentMonth')">{{
                        isCollapsed('currentMonth') ? 'expand_more' : 'expand_less'
                        }}</mat-icon>
                </div>
                <div *ngIf="!isCollapsed('currentMonth')" class="chat-list">
                    <div *ngIf="chatCurrentMonthData.length > 0; else noData">
                        <div class="list-style" *ngFor="let chat of chatCurrentMonthData; let i = index"
                            [class.selected]="isItemSelected(i)" (click)="selectItem(chat, i, 'currentMonth')">
                            {{ dateFormatService.convertUtcToLocalUnderScore(chat.startTime) }}
                        </div>
                    </div>
                    <ng-template #noData>
                        <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" fxFlexAlign="center">
                            <div class="loader"></div>
                        </div>
                        <div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center center">
                            <div>{{'sideNav.NO_DATA' | translate}}</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="isChatConversationHistory()">
            <div class="custom-radio-group" *ngIf="roles.includes('chatanalyst')">
                <div>
                    <mat-label>{{ 'sideNav.CHAT_TRANSCRIPT_USR_TYPE' | translate }}:</mat-label>
                </div>
                <mat-radio-group [(ngModel)]="userType" (ngModelChange)="onUserTypeChange($event)">
                    <mat-radio-button value="all">{{'sideNav.CHAT_TRANSCRIPT_ALL' | translate}}</mat-radio-button>
                    <mat-radio-button value="specified">{{'sideNav.CHAT_TRANSCRIPT_SPEC' |
                        translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="row" *ngIf="userType !=='all'">
                <div class="padding-left-right10">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'sideNav.EMAIL'| translate}}</mat-label>
                        <input matInput type="email" [(ngModel)]="email" name="email" id="email" required
                            [disabled]="shouldDisableEmail(roles)">
                    </mat-form-field>
                    <div class="note bg-white font-size12" *ngIf="!shouldDisableEmail(roles)">
                        * {{'sideNav.EMAIL_NOTE' | translate}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="padding-left-right10">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'sideNav.START_DATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="startDate" name="startDate"
                            id="startDate" [max]="today" required>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="padding-left-right10">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'sideNav.END_DATE' | translate}}</mat-label>
                        <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="endDate" name="endDate"
                            id="endDate" [min]="startDate" [max]="today" required>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <!-- Time Picker, displayed only when start and end dates are the same -->
            <div class="row" *ngIf="isSameDate(startDate, endDate)">
                <div class="padding-left-right10">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'sideNav.START_TIME' | translate }}</mat-label>
                      <mat-select [(ngModel)]="startTime" name="startTime" required (selectionChange)="validateTimes()">
                        <mat-option *ngFor="let hour of hoursList" [value]="hour">{{ hour }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="padding-left-right10">
                    <mat-form-field appearance="fill">
                      <mat-label>{{ 'sideNav.END_TIME' | translate }}</mat-label>
                      <mat-select [(ngModel)]="endTime" name="endTime" required (selectionChange)="validateTimes()">
                        <mat-option *ngFor="let hour of hoursList" [value]="hour">{{ hour }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="endTimeInvalid"> * {{ 'errorMessages.END_TIME_VALIDATION' | translate }}</mat-error>
                  </div>
            </div>
            <div class="row">
                <div class="padding-left-right10 text-right">
                    <button mat-raised-button class="mui-button-clear" (click)="onClear()">{{'sideNav.CLEAR' |
                        translate}}</button>
                    <button mat-raised-button class="mui-button" [disabled]="!isFormValid()"
                        (click)="onSearch()">{{'sideNav.SEARCH' |
                        translate}}</button>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="isContentManagement()" class="white-color-section content-mgmt-filter">
            <div class="justify-ctn-center">
                <div>{{'sideNav.ADD_CONTENT' | translate}}</div>
            </div>
            <div>
                <label>{{'sideNav.CONTENT_TYPE' | translate}}:</label>
            </div>
            <div>
                <mat-radio-group class="custom-radio-group" [(ngModel)]="selectedOption" (change)="handleRadioChange()">
                    <mat-radio-button value="file">{{'sideNav.FILE' | translate}}</mat-radio-button>
                    <mat-radio-button value="website">{{'sideNav.WEBSITE' | translate}}</mat-radio-button>
                    <mat-radio-button value="html">{{'sideNav.HTML' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <br>
            <div *ngIf="selectedOption === 'file'">
                <div class="padding-bottom15"
                    *ngIf="chatbotService?.botConfigurationValues?.enable_disclosure ==='true'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20">
                        <label>{{'disclosure.DISCLOSURE' | translate}}:</label>
                        <div class="toggle">
                            <input type="checkbox" id="toggle" [(ngModel)]="isDisclosure" class="toggle-input">
                            <label for="toggle" class="toggle-label">
                                <span class="toggle-on">ON</span>
                                <span class="toggle-off">OFF</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="padding-bottom15" *ngIf="chatbotService?.botConfigurationValues?.enable_image ==='true'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20">
                        <label>{{'disclosure.IMG_REQUIRED' | translate}}:</label>
                        <div class="toggle">
                            <input type="checkbox" id="img-toggle" [(ngModel)]="imageRequired" class="toggle-input">
                            <label for="img-toggle" class="toggle-label">
                                <span class="toggle-on">ON</span>
                                <span class="toggle-off">OFF</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="isDisclosure" fxLayoutAlign="center center"
                    class="padding-bottom15 font-size12">
                    *{{'disclosure.DIS_NOTES' | translate}}</div>
                <div fxLayout="row" *ngIf="!isDisclosure" fxLayoutAlign="center center"
                    class="padding-bottom15 font-size12">
                    *{{'disclosure.DIS_NOTES_DOC' | translate}}</div>
                <div class="file-upload">
                    <div class="drop-area" (drop)="handleDrop($event)" (dragover)="preventDefault($event)">
                        <ng-container *ngIf="selectedFile; else fileNotSelected">
                            <div class="selected-file">
                                <p>{{ selectedFile.name }}</p>
                                <p>{{ formatSize(selectedFile.size) }}</p>
                                <p (click)="handleDeleteFile()">
                                    <img src="assets/trash 1-white.svg" alt="Remove File" /> {{'sideNav.REMOVE' |
                                    translate}}
                                </p>
                            </div>
                        </ng-container>
                        <ng-template #fileNotSelected>
                            <div>
                                <div class="head-center">
                                    <img src="assets/file-upload.svg" height="50" alt="Upload Icon" />
                                </div>
                                <div class="head-center" (click)="triggerFileInput()">
                                    <label for="file-input" class="head-center">
                                        {{'sideNav.DRAG_DROP_TEXT' | translate}} <span
                                            class="choose-file">{{'sideNav.CHOOSE_FILE' | translate}}</span>
                                    </label>
                                    <input type="file" id="file-input" [accept]="isDisclosure ? '.xlsx' : '.pdf,.docx'"
                                        (change)="handleFileChange($event)" class="input-file-style" />
                                </div>
                                <br>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <br>
                <mat-form-field *ngIf="selectedFile?.name">
                    <mat-label>{{'sideNav.FILE_TYPE' | translate}}</mat-label>
                    <mat-select [(ngModel)]="fileType" [disabled]="fileUploaded" required>
                        <mat-option *ngFor="let type of fileTypes" [value]="type.value">{{ type.label }}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div *ngIf="selectedOption === 'website'">
                <mat-form-field>
                    <mat-label>{{'sideNav.Website_URL' | translate}}</mat-label>
                    <input matInput placeholder="{{'sideNav.Website_URL' | translate}}" [(ngModel)]="websiteUrl"
                        (input)="updateExcludedValues()" required>
                </mat-form-field>
                <div fxLayout="row" fxLayoutGap="20">
                    <mat-form-field>
                        <mat-label>{{'sideNav.Enter_Excluded_Value' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newExcludedValue" required>
                    </mat-form-field>
                    <button matTooltip="{{'sideNav.add_Excluded_Value' | translate}}" mat-icon-button
                        (click)="addExcludedValue()"><mat-icon
                            (click)="showFileName()">add_circle_outline</mat-icon></button>
                </div>
                <mat-form-field *ngIf="excludedValues.length > 0">
                    <mat-label>{{'sideNav.EXCLUDED_URL' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedExcludedValue" multiple disabled="true">
                        <mat-option *ngFor="let value of excludedValues" [value]="value">{{ value }}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <br>
            <div *ngIf="selectedOption === 'html'">
                <mat-form-field>
                    <mat-label>{{'sideNav.HTML_LINK' | translate}}</mat-label>
                    <input matInput [(ngModel)]="htmlLink" placeholder="{{'sideNav.Enter_HTML_Link'| translate}}"
                        required>
                </mat-form-field>
            </div>
            <div *ngIf="selectedOption === 'html' || selectedOption === 'website' || selectedOption === 'file'">
                <mat-form-field>
                    <mat-label>{{'sideNav.LANGUAGE_TYPE' | translate}}</mat-label>
                    <mat-select [(ngModel)]="languageType" required>
                        <mat-option *ngFor="let lang of languageTypes" [value]="lang">{{ lang }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'contentMgmt.BOOSTING_SCORE' | translate}}</mat-label>
                    <mat-select [(ngModel)]="boostScore" required>
                        <mat-option *ngFor="let score of boostScores" [value]="score">{{ score }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'sideNav.CONTENT_NAME' | translate}}</mat-label>
                    <input matInput [(ngModel)]="contentName" (ngModelChange)="replaceSpaces($event)"
                        placeholder="{{'sideNav.Enter_Content_Name'|translate}}" required>
                </mat-form-field>

                <button mat-raised-button style="width: 100%;" class="mui-button"
                    [disabled]=" this.selectedFile?.name?.split('.')?.pop() === 'odt'"
                    (click)="addContent()">{{'sideNav.ADD_CONTENT' | translate}}</button>

            </div>
        </div> -->
    </div>
    <div class="bottom-section">
        <div class="menu">
            <div class="menu-item" [class.active]="isChatbotUrl()" *ngIf="roles &&  roles.includes('user')"
                (click)="goToChatbot()">
                <span><img alt='Chatbot' height="28" class="invert-img" src='assets/bot.svg' /></span>
                <span>{{'sideNav.CHAT_BOT' | translate}}</span>
            </div>
            <div class="menu-item" [class.active]="isChatConversationHistory()"
                *ngIf="roles && (roles.includes('chatanalyst') || roles.includes('user'))"
                (click)="gotoChatTranscript()">
                <span><img alt='chat transcript' height="25" src='assets/message 1.svg' /></span>
                <span>{{'sideNav.CHAT_TRANSCRIPT' | translate}}</span>
            </div>
            <div class="menu-item" [class.active]="isAPIConfiguration()" *ngIf="roles && roles.includes('configadmin')"
                (click)="goToDevConfig()">
                <span><img alt='configuration' height="25" src='assets/setting 1.svg' /></span>
                <span>{{'sideNav.CONFIGURATION' | translate}}</span>
            </div>
            <div class="menu-item" *ngIf="roles && roles.includes('evaluator')" [class.active]="isValidator()"
                (click)="goToValidation()">
                <span><img alt='validator' height="28" class="invert-img" src='assets/validator-icon.svg' /></span>
                <span>{{'sideNav.VALIDATION_URL' | translate}}</span>
            </div>
            <div class="menu-item" *ngIf="roles && roles.includes('contentmanager')"
                [class.active]="isCache() || isContentManagement() || isFeedback() || isSynonyms() || isUserComments() || isReports()">
                <div class="menu-alignment-parant">
                    <div class="menu-alignment">
                        <span><img alt='Content Management' height="25" src='assets/content-mgmt-icon.svg' /></span>
                        <span class="padding-right-50">{{'sideNav.CONTENT_MGMT' | translate}}</span>
                    </div>
                    <div>
                        <span mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </span>
                    </div>
                </div>
            </div>
            <div class="nested-menu">
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="roles && roles.includes('contentmanager')"
                        (click)="gotoContentManagement()" [class.disabled]="isContentManagement()">
                        <img alt='Content' class="img_black_color" height="18" src='assets/content-mgmt-icon.svg' />
                        {{'sideNav.CONTENT' | translate}}
                    </button>
                    <button mat-menu-item
                        *ngIf="roles && roles.includes('contentmanager') && chatbotService?.botConfigurationValues?.enable_synonyms ==='true'"
                        (click)="goToSynonyms()" [class.disabled]="isSynonyms()">
                        <img alt='Dictionary' height="20" src='assets/dictionary.svg' />
                        {{'sideNav.SYNONYMS' | translate}}
                    </button>
                    <button mat-menu-item *ngIf="roles && roles.includes('contentmanager')"
                        (click)="gotoRatingTypeOption()" [class.disabled]="isFeedback()">
                        <img alt='Rating' height="20" src='assets/Feedback.svg' />
                        {{'sensitiveQuestion.RATING_TYPES_OPTIONS' | translate}}
                    </button>
                    <button mat-menu-item *ngIf="roles && roles.includes('contentmanager')" (click)="gotoCache()"
                        [class.disabled]="isCache()">
                        <img alt='Cache' height="20" src='assets/store.svg' />
                        {{'sideNav.CACHE' | translate}}
                    </button>
                    <button mat-menu-item (click)="gotoUserComments()" [class.disabled]="isUserComments()"
                        *ngIf="roles && roles.includes('contentmanager')">
                        <img alt='Chit chat' height="20" src='assets/chitchat.png' />
                        {{'sideNav.FEEDBACK_CMD' | translate}}
                    </button>
                    <button mat-menu-item (click)="gotoReports()" [class.disabled]="isReports()"
                        *ngIf="roles && roles.includes('contentmanager')">
                        <img alt='Chit chat' height="20" src='assets/report.png' />
                        {{'sideNav.REPORTS' | translate}}
                    </button>
                </mat-menu>
            </div>
            <div class="menu-item" [class.active]="isConfiguration()" *ngIf="roles && roles.includes('botadmin')"
                (click)="gotoConfiguration()">
                <span><img alt='Chatbot' class="invert-img" height="25" src='assets/config.svg' /></span>
                <span>{{'sideNav.BOT_CONFIG' | translate}}</span>
            </div>
            <div class="menu-item" [class.active]="isUserManagement()" *ngIf="roles && roles.includes('useradmin')"
                (click)="gotoUserManagement()">
                <span><img alt='Chatbot' height="25" src='assets/manage user.svg' /></span>
                <span>{{'sideNav.USER_MGMT' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="profile-menu-item" (click)="toggleProfileMenu($event)" fxLayout="row"
        fxLayoutAlign="space-between center">
        <!-- Left side content -->
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <div class="padding-left-10">
                <img [src]="chatbotService.profilePictureUrl" alt="Profile Picture" height="25"
                    class="profile-border-radius50" />
            </div>
            <div>
                <div class="font-size12">{{firstName}} {{lastName}}</div>
                <div class="font-size10">{{userProfileName}}</div>
            </div>
        </div>
        <!-- Right side content -->
        <div class="padding-right-10">
            <img alt="profile" height="20" class="invert-img" src="assets/angle-down-solid.svg" />
        </div>
    </div>
    <div class="pop-profile-menu" [style.display]="profileMenuVisible ? 'block' : 'none'">
        <div class="pop-menu-item language_width238" *ngIf="botType !=='cibc'">
            <mat-form-field appearance="fill">
                <mat-label>{{'devConfig.SELECT_LANGUAGE' | translate}}</mat-label>
                <mat-select [(value)]="selectedLanguage" (selectionChange)="languageChanged()">
                    <mat-option value="en">English</mat-option>
                    <mat-option value="fr">Français</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="pop-menu-item language_width238" (click)="closeProfileMenu()">
            <span><img [src]="chatbotService.profilePictureUrl" alt="Profile Picture" height="30"
                    class="profile-border-radius50" /></span>
            <span>{{firstName}} {{lastName}}</span>
        </div>
        <div class="pop-menu-item language_width238" (click)="removeProfileImage()"
            [ngClass]=" chatbotService.profilePictureUrl === 'assets/noImage.png' ? 'disabled' : '' ">
            <span><img alt='Logout' height="20" src='assets/remove-user.png' /></span>
            <span>{{'sideNav.REMOVE_PROFILE_IMG' | translate}} </span>
        </div>
        <div class="pop-menu-item language_width238" (click)="changeProfileImage()">
            <span><img alt='Logout' height="20" src='assets/user 1.svg' /></span>
            <span>{{'sideNav.CHANGE_PROFILE_IMG' | translate}} </span>
        </div>
        <div class="pop-menu-item language_width238" (click)="changePassword()">
            <span><img alt='Logout' height="20" src='assets/reset-password.png' /></span>
            <span>{{'sideNav.CHANGE_PASSWORD' | translate}} </span>
        </div>
        <div class="pop-menu-item language_width238" (click)="logout()">
            <span><img alt='Logout' height="20" src='assets/logout.svg' /></span>
            <span>{{'sideNav.LOGOUT' | translate}} </span>
        </div>
        <div class="pop-menu-item language_width238">
            <span><mat-icon>info</mat-icon></span>
            <span>Version 17</span>
        </div>
    </div>
    <div *ngIf="isModalLoader" class="modal-backdrop">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>