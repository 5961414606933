import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorageService';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { Observable } from 'rxjs';
import { ChatbotService } from './chat.service';
import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ValidatorServiceService {
  private validatorBaseURL = environment.apiBaseUrl['validatorURL'];
  private token = '';
  private headers: HttpHeaders = new HttpHeaders();
  validatorTableFilter: any = null;
  constructor(private http: HttpClient, private sessionStorage: LocalStorageService, private chatbotService: ChatbotService, private toastr: ToastrService,
    private translated: TranslateService,
    private router: Router) {
    //this.updateToken();
  }

  // private updateToken(): void {
  //   this.token = this.sessionStorage.get('appTo');
  //   this.headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.token}`
  //   });
  // }

  getValidatorTestData(payload: any, page_number: any, page_size: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/fetch_user_details?page_size=${page_size}&page_number=${page_number}
    `, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  uploadValidation(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/upload`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  getValidationDetailInfo(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/fetch_run_data`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  searchValidationDetail(payload: any, page_number: any, page_size: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/search_text?page_size=${page_size}&page_number=${page_number}`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  deleteValidation(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/delete`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  downloadValidationDetails(runid: any) {
    //this.updateToken();
    const body = { run_id: runid };

    return this.http.post(`${this.validatorBaseURL}/download_file`, body, { headers: this.headers, responseType: 'blob' }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  downloadCompareData(runid: any) {
    //this.updateToken();
    const body = { run_id: runid };

    return this.http.post(`${this.validatorBaseURL}/download_file_compare`, body, { headers: this.headers, responseType: 'blob' }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getValidatorOptions(): Observable<any[]> {
    //this.updateToken();
    const body = { bot_type: this.sessionStorage.get('bot_type') };
    return this.http.post<any[]>(`${this.validatorBaseURL}/get_file`, body, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  getCompareData(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/compare`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  downloadSample(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/download_template`, payload, {
      headers: this.headers,
      responseType: 'blob'
    }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  validatorRephrase(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/add`, payload, {
      headers: this.headers,
      responseType: 'blob'
    }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  updateValidatorWithFavorites(payload: any) {
    //this.updateToken();
    return this.http.post(`${this.validatorBaseURL}/update_favorite`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {
       
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
}
