import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentManagementService } from '../services/content-management.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  apiData: any;
  loading: boolean = true;
  secondFormGroup!: FormGroup;
  botLoderType = 'boxLoader';
  isFormDirty: boolean = false;
  isAnyFieldEmpty: boolean = false;
  originalFormValues: any;

  constructor(private contentMgmtService: ContentManagementService, private _formBuilder: FormBuilder,
    private toaster: ToastrService, private translated: TranslateService, private router: Router) { }

  ngOnInit(): void {
    this.getValue();
    this.secondFormGroup = this._formBuilder.group({});
    this.subscribeToFormChanges();
  }

  getValue() {
    this.loading = true
    this.contentMgmtService.getContentConfigurationData().subscribe((data: any) => {
      this.apiData = data;
      this.apiData.data.forEach((item: any) => {
        let control: any;
        if (item.input_type === 'toggle') {
          control = this._formBuilder.control(item?.value === 'true'); // Convert 'true'/'false' to boolean
        } else {
          control = this._formBuilder.control(item?.value || '');
        }
        this.secondFormGroup.addControl(item?.key, control);
      });
      this.loading = false;
      this.originalFormValues = this.secondFormGroup?.value; // Save the original form values
    }, (error: any) => {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
    });
  }

  save() {
    // Prepare the data to send to the backend
    const updatedData = { ...this.apiData };

    // Update the values of the items in the updated data
    updatedData.data.forEach((item: any) => {
      if (item.input_type === 'toggle') {
        // Convert boolean value to string 'true' or 'false'
        item.value = this.secondFormGroup.get(item.key)?.value ? 'true' : 'false';
      } else {
        // For other types, directly use the form value
        item.value = this.secondFormGroup.get(item.key)?.value;
      }
    });

    // Now you can send the updated data to the backend API
    this.contentMgmtService.saveContentConfigurationData(updatedData).subscribe(
      (response: any) => {
        // Handle successful save response if needed
        this.translated.get('successfullMessages.CONFIG_UPDATED_SUCCESS').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
        this.getValue();
      },
      (error: any) => {
        // Handle error if save fails
        // this.toaster.error(error);
      }
    );
  }

  // Method to reset the form to original values
  resetForm() {
    this.secondFormGroup.reset(this.originalFormValues); // Reset the form to original values
    this.isFormDirty = this.isFormDirtyCheck();
  }

  isFormDirtyCheck() {
    // return Object.keys(this.secondFormGroup.controls).some(key => {
    //   return this.secondFormGroup.get(key)?.value !== this.originalFormValues[key];
    // });

    // Ensure originalFormValues is defined
    if (!this.originalFormValues) {
      // console.error('originalFormValues is not defined');
      return false;
    }

    return Object.keys(this.secondFormGroup.controls).some(key => {
      // Ensure the key exists in originalFormValues
      if (this.originalFormValues[key] === undefined) {
        console.warn(`Key ${key} is not defined in originalFormValues`);
        return false;
      }

      // Get the current value from the form group control
      const currentValue = this.secondFormGroup.get(key)?.value;

      // Compare the current value with the original value
      return currentValue !== this.originalFormValues[key];
    });
  }

  createForm(item: any): FormGroup {
    const control = this._formBuilder.control(item.value || '');
    this.secondFormGroup.addControl(item.key, control);
    return this._formBuilder.group({
      [item.key]: control
    });
  }

  private subscribeToFormChanges() {
    this.secondFormGroup.valueChanges.subscribe(() => {
      this.isFormDirty = this.isFormDirtyCheck();
      this.isAnyFieldEmpty = this.checkForEmptyFields();
    });
  }

  private checkForEmptyFields(): boolean {
    for (const controlName in this.secondFormGroup.controls) {
      if (this.secondFormGroup.controls.hasOwnProperty(controlName)) {
        const control = this.secondFormGroup.controls[controlName];
        if (control.invalid && (control.errors?.['required'] || control.value === '')) {
          return true;
        }
      }
    }
    return false;
  }
}
